import { createReducer } from 'deox';
import { Resource } from '../types';
import {
  fetchResourcesError,
  fetchResourcesRequest,
  fetchResourcesSuccess,
  resetResources,
} from './actions';

export interface State {
  resources: Resource[] | null;
  acoBookingResources: Resource[] | null;
  isLoading: boolean;
  error: Error | null;
}

const defaultState: State = {
  resources: null,
  acoBookingResources: null,
  isLoading: false,
  error: null,
};

const resourceReducer = createReducer(defaultState, (handle) => [
  handle(fetchResourcesRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  handle(fetchResourcesSuccess, (state, { payload }) => ({
    ...state,
    resources: payload.resources.resources,
    acoBookingResources: payload.resources.acoBookingResources,
    isLoading: false,
  })),
  handle(fetchResourcesError, (state, { payload }) => ({
    ...state,
    error: payload.error,
    isLoading: false,
  })),
  handle(resetResources, (state) => ({
    ...defaultState,
  })),
]);

export default resourceReducer;
