import { createReducer } from 'deox';
import { RegistrationStep } from '../../user/forces/types';
import {
  registerUserError,
  registerUserRequest,
  registerUserSuccess,
  resetRegisterForm,
  setCurrentStep,
  setReferralCode,
} from './actions';

interface State {
  currentStep: RegistrationStep;
  referralCode: string;
  error: Error | null;
  isLoading: boolean;
}

const defaultState: State = {
  currentStep: RegistrationStep.GeneralForm,
  referralCode: '',
  error: null,
  isLoading: false,
};

export default createReducer(defaultState, (handle) => [
  handle(registerUserRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  handle(registerUserSuccess, (state) => ({
    ...state,
    currentStep: RegistrationStep.Summary,
    error: null,
    isLoading: false,
  })),
  handle(registerUserError, (state, { payload }) => ({
    ...state,
    error: payload.error,
    isLoading: false,
  })),
  handle(setCurrentStep, (state, { payload }) => ({
    ...state,
    currentStep: payload.currentStep,
    error: null,
    isLoading: false,
  })),
  handle(resetRegisterForm, () => ({
    ...defaultState,
  })),
  handle(setReferralCode, (state, { payload }) => ({
    ...state,
    referralCode: payload.referralCode,
  })),
]);
