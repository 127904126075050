import { createReducer } from 'deox';
import { setSelectedClinic, setSelectedResource } from '../../../forces/actions';
import {
  fetchAvailabilityDateRequest,
  fetchAvailabilityError,
  fetchAvailableDateSuccess,
} from './actions';
import { AvailableDate } from './types';

interface State {
  availableDates: AvailableDate[];
  additionalAvailableDates: {
    isLoading: boolean;
  };
  isLoading: boolean;
  error: Error | null;
}

const defaultState: State = {
  availableDates: [],
  additionalAvailableDates: {
    isLoading: false,
  },
  isLoading: false,
  error: null,
};

const calendarReducer = createReducer(defaultState, (handle) => [
  handle(fetchAvailabilityDateRequest, (state, { payload }) => ({
    ...state,
    error: null,
    isLoading: true,
    additionalAvailableDates: {
      isLoading: payload.isAdditional || false,
    },
    availableDates: [...state.availableDates],
  })),
  handle(fetchAvailableDateSuccess, (state, { payload }) => ({
    ...state,
    error: null,
    isLoading: false,
    additionalAvailableDates: {
      isLoading: false,
    },
    availableDates: payload.options.appendData
      ? [...state.availableDates, ...payload.availableDatesDirectResponse]
      : payload.availableDatesDirectResponse,
  })),
  handle(fetchAvailabilityError, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload.error,
  })),
  handle(setSelectedClinic, (state) => ({
    ...state,
    availableDates: [],
  })),
  handle(setSelectedResource, (state) => ({
    ...state,
    availableDates: [],
  })),
]);

export default calendarReducer;
