import { appConfig } from 'config';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment, { Moment } from 'moment';

dayjs.extend(utc);
dayjs.extend(tz);

const TIME_CONSTANTS = {
  SECONDS: {
    IN_HOURS: 60 * 60,
    IN_MINUTES: 60,
  },
  MILLISECONDS: {
    IN_HOURS: 60 * 60 * 1000,
    IN_MINUTES: 60 * 1000,
  },
  MINUTES: {
    IN_MILLISECONDS: 60 / 1000,
  },
};

export const getMomentFromIsoString = (isoString: string): Moment => {
  const date = moment(isoString);

  // TODO: Don't push this while having the integration with Waitwhile
  if (appConfig.acobooking.isActive) {
    return date;
  }

  const offset = date.utcOffset();
  const hoursOfOffset = Math.floor(offset / 60);

  const timezoneString = isoString.replace('Z', `+0${hoursOfOffset}:00`);
  return moment(timezoneString);
};

// TODO: Make sure this is correct for ACO Booking/non-ACO Booking
export const getHourMinFormatFromIso = (isoString: string): string => {
  const value = getMomentFromIsoString(isoString);
  return value.format('HH:mm');
};

export const getSummaryFormatFromIso = (isoString: string): string => {
  const value = getMomentFromIsoString(isoString);

  return value.format('DD.MM.YYYY, HH:mm');
};

export const getMSValueForIso = (date: string) => {
  const value = getMomentFromIsoString(date);

  return (
    value.hour() * TIME_CONSTANTS.MILLISECONDS.IN_HOURS +
    (value.minute() + TIME_CONSTANTS.MILLISECONDS.IN_MINUTES)
  );
};

export const formatIsoToDateAndYear = (iso: string) => {
  const value = getMomentFromIsoString(iso);

  return value.format('DD.MM.YYYY');
};

export const formatIsoToTime = (iso: string) => {
  const value = getMomentFromIsoString(iso);
  return value.format('HH:mm');
};

export const formatIsoStringToDateKey = (date: string) =>
  getMomentFromIsoString(date).format('DD-MM-YYYY');

export const formatToAPIDate = (date: string | number | Moment) =>
  moment(date).format('DD-MM-YYYY');

export const formatToDateAndYear = (utcDate: string | number | string) => {
  return moment(utcDate).format('DD.MM.YYYY');
};
export const formatToTime = (utcTime: number) => {
  return moment.utc(utcTime).local().format('HH:mm');
};

export const formatDurationToTime = (durationMilliseconds: number) => {
  return moment.utc(durationMilliseconds).format('HH:mm');
};

export const millisecondsToMinutes = (milliseconds: number) =>
  milliseconds / TIME_CONSTANTS.MINUTES.IN_MILLISECONDS;
export const formatToDateAndTime = (date: number) => {
  return `${formatToDateAndYear(date)} ${formatToTime(date)}`;
};

export const formatDateString = (utcDate: string | number) => {
  return dayjs(utcDate).tz('Europe/Oslo').format('dddd D. MMM HH:mm');
};

export const formatDateStringWithYear = (utcDate: string | number) => {
  return dayjs(utcDate).tz('Europe/Oslo').format('dddd D. MMM YYYY HH:mm');
};

export const formatToDateAndTimeLong = (utcDate: string | number) => {
  return dayjs(utcDate).tz('Europe/Oslo').format('dddd D. MMM YYYY HH:mm');
};

export const formatDateToTime = (utcDate: number) => {
  let formattedDate = moment.utc(utcDate).local().format('HH:mm');
  return formattedDate;
};

export const calculateClockFromMSTime = (duration: number): string => {
  let minutes: number | string = Math.floor(
    (duration / TIME_CONSTANTS.MILLISECONDS.IN_MINUTES) % 60
  );
  let hours: number | string = Math.floor((duration / TIME_CONSTANTS.MILLISECONDS.IN_HOURS) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return hours + ':' + minutes;
};

export const isDateInThePast = (unixTimestamp: number) => {
  return unixTimestamp < new Date().getTime();
};

export const dayIsBefore = (date: Moment): boolean => {
  return date.isBefore(moment(), 'day');
};

export const numericDateToMoment = (date: number): Moment => {
  return moment(date);
};

export const dateToMoment = (date: string): Moment => {
  return moment(date);
};

export const isSelectedDateCurrentMonth = (dateTime: string): boolean => {
  let selected = new Date(dateTime);
  let today = new Date();
  return selected.getMonth() === today.getMonth();
};

export const getStartOfMonth = (date: Moment) => {
  return date.startOf('month');
};

export const getEndOfMonth = (date: Moment) => {
  return date.endOf('month');
};

export const getStartOfDay = (date: Moment) => {
  return date.startOf('day');
};

export const twentyfourHoursFromNowInUnixTime = () => {
  return Math.round(new Date().getTime() + 24 * 60 * 60 * 1000);
};

export const isBookingStartInTwentyfourHours = (date: string) => {
  return new Date(date).getTime() < twentyfourHoursFromNowInUnixTime();
};

export const sortByTime = (a: string | number, b: string | number, descending?: boolean) => {
  const compareTime = descending ? dayjs(a).isBefore(dayjs(b)) : dayjs(a).isAfter(dayjs(b));
  return compareTime ? 1 : -1;
};

export const formatDateToWrittenDateTime = (date: string) => {
  return dayjs(date).format('DD. MMM HH:mm');
};
