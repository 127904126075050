import classNames from 'classnames';
import { XCircle } from 'common/components/icons/Icons';
import { useSelector } from '../../../../../../common/store/hooks';
import { getInvalidSections } from '../../../../forces/selectors';
import styles from './SummarySectionNew.module.css';

const InvalidSections = () => {
  const invalidSections = useSelector(getInvalidSections)();
  return (
    <div className={classNames(styles.invalidSections, styles.redIcon)}>
      {invalidSections.map((section) => (
        <div className={styles.iconRow}>
          <XCircle /> {section}
        </div>
      ))}
    </div>
  );
};

export default InvalidSections;
