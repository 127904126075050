import { RouteComponentProps, Router as ReachRouter } from '@reach/router';
import '../../assets/styles/main.module.css';
import Logout from '../auth/components/Logout';
import LoginPage from '../auth/LoginPage';
import BookingPage from '../booking/BookingPage';
import BookingSuccess from '../booking/components/BookingSuccess';
import RebookingSuccess from '../booking/components/RebookingSuccess';
import RebookingPage from '../booking/RebookingPage';
import RegisterPage from '../register/RegisterPage';
import ForgotPassword from '../user/components/ForgotPassword';
import GuestAuth from '../user/components/GuestAuth';
import PrivateRoute from '../user/components/PrivateRoute';
import { Role } from '../user/forces/types';
import UserPage from '../user/UserPage';
import Routes, { RoutePaths } from './Routes';

const Router = () => {
  return (
    <ReachRouter>
      <NotFoundRoute default />
      <BookingPage path={Routes.Main()} />
      <RebookingPage path={Routes.Rebooking()} />
      <BookingSuccess path={Routes.BookingSuccess()} />
      <RebookingSuccess path={Routes.RebookingSuccess()} />
      <LoginPage path={Routes.LogIn()} />
      <RegisterPage path={Routes.Register()} />
      <ForgotPassword path={Routes.ForgotPassword()} />
      <GuestAuth path={RoutePaths.GuestAuthentication} />
      <PrivateRoute path={RoutePaths.Logout} component={Logout} />
      <PrivateRoute path={RoutePaths.UserPage} component={UserPage} />
      <PrivateRoute
        roles={[Role.Guest, Role.Member]}
        path={RoutePaths.UpdateBooking}
        component={BookingPage}
      />
    </ReachRouter>
  );
};

const NotFoundRoute = (props: RouteComponentProps) => {
  window.location.href = '/';
  return null;
};

export default Router;
