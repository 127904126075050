import { useCallback } from 'react';
import { useSelector } from '../store/hooks';
import { getToken } from '../../domain/user/forces/selectors';

export const useAuthenticatedFetcher = () => {
  const token = useSelector(getToken);
  const fetcher = useCallback(
    (slug) =>
      fetch(slug, {
        headers: {
          Authorization: token,
        },
      }).then((res) => res.json()),
    [token]
  );

  return fetcher;
};
