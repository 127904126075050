import { TimeSlot as TimeSlotType, TimeSlotRenderProps } from '@askeladden/availability-ui';
import classNames from 'classnames';
import { Slot } from '../forces/types';
import styles from './TimePicker.module.css';

const TimePicker = (timeSlot: TimeSlotType & TimeSlotRenderProps & Slot) => {
  const isAvailable = timeSlot.isAvailable;
  const isSelected = timeSlot.isSelected;

  const determineStyling = () => {
    if (isSelected) {
      return styles.selected;
    }
    if (!isAvailable) {
      return styles.unavailable;
    }
    if (!isSelected) {
      return styles.unselected;
    }
  };
  let buttonStyle = determineStyling();
  const cssClassName = classNames(styles.button, buttonStyle);
  const formattedDiscountValue = formatDiscountValue(timeSlot.discountValue);

  return (
    <button className={cssClassName} disabled={!isAvailable}>
      {formattedDiscountValue && (
        <div className={styles.discountBadge}>{formattedDiscountValue}</div>
      )}
      {timeSlot.clockString}
      <br />
    </button>
  );
};

export default TimePicker;

const formatDiscountValue = (discountValue: number): string | null => {
  if (discountValue <= 0) return null;
  return `${discountValue * 100}%`;
};
