import { RouteComponentProps } from '@reach/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../../../common/components/loadingIndicator/LoadingIndicator';
import { loginGuest } from '../../auth/forces/actions';
import { getCurrentUser } from '../forces/selectors';

interface RouterProps {
  userId: string;
}

type Props = RouteComponentProps<RouterProps>;

const GuestAuth = ({ userId }: Props) => {
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user && userId) {
      dispatch(loginGuest(userId));
    }
  }, [dispatch, user, userId]);

  return <LoadingIndicator />;
};

export default GuestAuth;
