import * as React from 'react';
import classNames from 'classnames';
import { H2 } from '../texts';
import styles from './SectionSubtitle.module.css';

type Props = {} & React.HTMLAttributes<HTMLElement>;

const SectionSubtitle: React.FunctionComponent<Props> = ({ className, children, ...props }) => {
  return (
    <H2 className={classNames(styles.text, className)} {...props}>
      {children}
    </H2>
  );
};

export default SectionSubtitle;
