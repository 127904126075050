import useSWR from 'swr';
import { appConfig } from '../../../config';

interface RebookingDataByBookingIdResponse {
  message: string[];
  code: number;
  data: Data;
}
interface Data {
  user: RebookingUser;
  usersWithSamePhoneNumber: RebookingUser[];
  serviceIds: string[];
  clinicId: string;
  resourceIds: string[];
}
export interface RebookingUser {
  id: string;
  name: string;
  role: string;
}

export const useUserByBookingId = (bookingId: string) => {
  const fetcher = (slug: string) => fetch(slug).then((res) => res.json());
  const { data, ...params } = useSWR<RebookingDataByBookingIdResponse>(
    appConfig.apiBaseUrl + `/users/bookings/` + bookingId,
    fetcher
  );

  if (!data) return { data: undefined, ...params };
  return { data, ...params };
};
