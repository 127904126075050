import posthog from 'posthog-js';
import { splitFirstAndLastName } from '../utils/formatting.utils';
import { User } from '@sentry/react';
import { GuestInfo } from '../../domain/user/forces/types';

export type BookingSteps =
  | 'Velg behandling'
  | 'Velg underbehandling'
  | 'Velg lokasjon'
  | 'Velg behandler'
  | 'Velg dato'
  | 'Velg tid'
  | 'Gjesteinfo';

export const identifyUser = async (user: User | GuestInfo) => {
  const { firstName, lastName } = splitFirstAndLastName(user.name);
  posthog.identify(user.email, {
    email: user.email,
    firstName,
    lastName,
    phone: user.phoneNumber,
  });
};

export const trackBookingStep = async (step: BookingSteps, additionalData?: Object) => {
  await posthog.capture(`Bookingstep: ${step}`, {
    step,
    additionalData,
  });
};
