import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { appConfig } from '../../config';
import Routes from '../../domain/app/Routes';
import rootReducer, { RootState } from '../../root.reducer';
import { ThunkArguments } from '../../root.types';
import request from '../api/request';

const composeEnhancers =
  (typeof window !== 'undefined' &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const configureStore = (initialState?: Partial<RootState>) => {
  const apiRequest = request(appConfig.apiBaseUrl);
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk.withExtraArgument<ThunkArguments>({
          request: apiRequest,
          config: appConfig,
          routes: Routes,
        })
      )
    )
  );

  return store;
};

export default configureStore();
