import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../../../common/components/loadingIndicator/LoadingIndicator';
import { isAppSetupLoading } from '../../app/forces/selectors';
import Routes from '../../app/Routes';
import { getCurrentUser } from '../forces/selectors';
import { Role } from '../forces/types';

interface OwnProps {
  roles?: Array<Role>;
  component: React.ComponentType<RouteComponentProps>;
}

type Props = OwnProps & React.HTMLAttributes<HTMLElement> & RouteComponentProps;

const PrivateRoute = ({ component: Component, roles = [Role.Member], ...rest }: Props) => {
  const isLoading = useSelector(isAppSetupLoading);
  const user = useSelector(getCurrentUser);

  if (isLoading) return <LoadingIndicator />;
  if (!user) {
    window.location.href = Routes.LogIn();
    return null;
  }
  if (!user) return null;

  return <Component {...rest} />;
};

export default PrivateRoute;
