const isDev = process.env.NODE_ENV === 'development';

export const appConfig = {
  sanity: {
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: process.env.REACT_APP_SANITY_DATASET,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
    env: 'development',
  },
  websiteDomain: process.env.REACT_APP_WEBSITE_DOMAIN || '',
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
  referralCreditAward: process.env.REACT_APP_REFERRAL_CREDIT_AWARD || 200,
  signupCreditAward: process.env.REACT_APP_SIGNUP_CREDIT_AWARD || 200,
  bookingPointsEarningsPercentage: process.env.REACT_APP_BOOKING_POINTS_EARNINGS_PERCENTAGE || 10,
  bookingDisabled: process.env.REACT_APP_BOOKING_DISABLED === '1',
  showGlobalBannerAlert: process.env.REACT_APP_SHOW_GLOBAL_BANNER_ALERT === '1',
  environment: process.env.REACT_APP_ENVIRONMENT,
  gtm: isDev ? 'GTM-NRWTNHL' : 'GTM-TD27M2D',
  facebook: {
    pageId: process.env.REACT_APP_FACEBOOK_PAGE_ID,
  },
  segment: {
    writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  },
  typeform: {
    url: 'https://oliobeauty.typeform.com/kundeservice-v2?typeform-source=www.app.olio.no&hubspot_page_url=https://www.app.olio.no/',
  },
  acobooking: {
    isActive: true,
    activeClinicIds: [
      'Rv3ktsEle8AbhWaZKLqf',
      '2iZH42aDANo9SMFlXQaQ',
      'Etig1AfEhxnFhBzUUef9',
      'KrGDXVPIqBDwQKKNZolw',
      '3CMnSBq5mO7yAUMsaFrk',
      'rKDqWXqu0b9WGIAPrvUm',
      '7q9wTpkDD0H5Ur5hj2rW',
      '7eF3zNYAMU6LNSXzwOmA',
      'DJTSoryWxaHUiggq0WjT',
      'ZgHMtlL1luOdCPB10UZI',
      'PLIQM9iEt6uWHPP2JVrI',
      'd814a1a5-2284-4d52-ba6e-c0cb860aad33',
    ],
  },
};
