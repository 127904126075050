import { RouteComponentProps } from '@reach/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Container } from '../../common/components/grid/Grid';
import Layout from '../../common/components/layout/Layout';
import LoadingIndicator from '../../common/components/loadingIndicator/LoadingIndicator';
import { isAppSetupLoading } from '../app/forces/selectors';
import { populateRebooking } from './forces/actions';
import CalendarSection from './sections/calendarSection/CalendarSection';
import ClinicSection from './sections/clinicSection/ClinicSection';
import { getFetchServicesError } from './sections/serviceSection/forces/selectors';
import SummarySection from './sections/summarySection/SummarySection';
import { useUserByBookingId } from './hooks/useUserByBookingId';
import RebookingServiceSection from './sections/serviceSection/RebookingServiceSection';
import CreateRebookingButton from './sections/summarySection/components/CreateRebookingButton';
import { getRebookingUserPromptIsSubmitted, getSelectedResource } from './forces/selectors';
import RebookingResourceSection from './sections/resourceSection/RebookingResourceSection';
import RebookingOverview from './RebookingOverview';
import FetchErrorMessage from './components/FetchErrorMessage';

interface OwnProps {
  bookingId?: string;
}

type Props = OwnProps & RouteComponentProps;

const RebookingPage = ({ bookingId }: Props) => {
  const { data, isLoading, error } = useUserByBookingId(bookingId);
  const { clinicId, serviceIds, resourceIds } = data?.data ?? {
    clinicId: null,
    serviceIds: [],
    resourceIds: [],
  };

  const rebookingTherapist = useSelector(getSelectedResource);
  const isAppLoading = useSelector(isAppSetupLoading);
  const serviceFetchError = useSelector(getFetchServicesError);
  const rebookingUserPromptIsSubmitted = useSelector(getRebookingUserPromptIsSubmitted);
  const showLoader = isLoading || isAppLoading;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAppLoading && !clinicId && !resourceIds.length) {
      return;
    }
    dispatch(populateRebooking(clinicId, bookingId, serviceIds, resourceIds));
  }, [clinicId, bookingId, isAppLoading]);

  return (
    <Layout>
      <Container>
        <Column lgOffset={3} lg={6}>
          {showLoader ? (
            <LoadingIndicator />
          ) : serviceFetchError || error || !data?.data ? (
            <FetchErrorMessage />
          ) : (
            <>
              <RebookingOverview
                therapistName={rebookingTherapist?.name}
                bookingUser={data.data.user}
                userWithSamePhone={data.data.usersWithSamePhoneNumber}
              />
              {rebookingUserPromptIsSubmitted && (
                <>
                  <RebookingServiceSection />
                  <ClinicSection />
                  <RebookingResourceSection resourceIds={resourceIds} />
                  <CalendarSection defaultSelectFirstAvailable={false} />
                  <SummarySection />
                  <CreateRebookingButton />
                </>
              )}
            </>
          )}
        </Column>
      </Container>
    </Layout>
  );
};

export default RebookingPage;
