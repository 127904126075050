import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../common/components/button/Button';
import LoadingIndicator from '../../../common/components/loadingIndicator/LoadingIndicator';
import { H3 } from '../../../common/components/texts';
import { useSelector } from '../../../common/store/hooks';
import {
  formatDateString,
  formatDateStringWithYear,
  isDateInThePast,
  sortByTime,
} from '../../../common/utils/date.utils';
import { Booking } from '../../booking/forces/types';
import { fetchUserBookings, setActiveTabMenuContent } from '../forces/actions';
import { getActiveTabMenuContent, getIsLoadingUser, getUserBookings } from '../forces/selectors';
import { TabMenuContent } from '../UserPage';
import BookingInfo from './Bookinginfo';
import styles from './Bookings.module.css';

const UserBookings = () => {
  const [bookingToShow, setBookingToShow] = useState<Booking>();
  const activeTabMenuContent = useSelector(getActiveTabMenuContent);
  const bookings = useSelector(getUserBookings);
  const isLoading = useSelector(getIsLoadingUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserBookings());
  }, [dispatch]);

  const getUpComingBookings = () => {
    return bookings
      .filter((booking) => !isDateInThePast(booking.time))
      .sort((a, b) => sortByTime(a.time, b.time));
  };

  const getOldBookings = () => {
    return bookings
      .filter((booking) => isDateInThePast(booking.time))
      .sort((a, b) => sortByTime(a.time, b.time, true));
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const hasBookings = bookings.length > 0;

  const renderBookings = (bookings: Booking[]) => {
    return bookings
      .sort((a, b) => sortByTime(a.time, b.time, true))
      .map((booking) => {
        const isOldBooking = isDateInThePast(booking.time);
        return (
          <div style={{ opacity: isOldBooking ? 0.5 : 1 }} key={booking.id}>
            <Button
              className={styles.bookingButton}
              title={`${
                dayjs().isSame(booking.time, 'year')
                  ? formatDateString(booking.time)
                  : formatDateStringWithYear(booking.time)
              }`}
              onClick={() => {
                setBookingToShow(booking);
                dispatch(setActiveTabMenuContent(TabMenuContent.BookingInfo));
              }}
            />
          </div>
        );
      });
  };

  const upcomingBookings = getUpComingBookings();
  const hasUpcomingBookings = upcomingBookings.length > 0;
  const oldBookings = getOldBookings();
  const hasOldBookings = oldBookings.length > 0;

  if (activeTabMenuContent === TabMenuContent.BookingInfo && bookingToShow) {
    return <BookingInfo bookingId={bookingToShow.id} />;
  }

  return (
    <div>
      {!hasBookings && <H3 className={styles.hasNoBooking}>Du har ikke booket time enda.</H3>}
      {hasUpcomingBookings && (
        <div className={styles.upComingBookingsContainer}>
          <h3 className={styles.text}>Kommende bookinger</h3>
          {renderBookings(getUpComingBookings())}
        </div>
      )}
      {hasOldBookings && (
        <div>
          <h3 className={styles.text}>Tidligere bookinger</h3>
          {renderBookings(getOldBookings())}
        </div>
      )}
    </div>
  );
};

export default UserBookings;
