import classNames from 'classnames';
import { useState } from 'react';
import AnimateHeight from '../../../../../common/components/animateHeight/AnimateHeight';
import { H3 } from '../../../../../common/components/texts';
import { useSelector } from '../../../../../common/store/hooks';
import { Service, ServiceCategory } from '../../../../../root.types';
import { getActiveServicesByServiceCategoryId } from '../forces/selectors';
import ServiceItem from './ServiceItem';
import styles from './ServiceList.module.css';

interface Props {
  serviceCategory: ServiceCategory;
  isOpened: boolean;
  handleSelection: (service: Service) => void;
  handleDeselection: (service: Service) => void;
}

const ServiceList = ({ serviceCategory, isOpened, handleSelection, handleDeselection }: Props) => {
  const [isMenuListOpened, setIsMenuListOpened] = useState(isOpened);
  const services = useSelector(getActiveServicesByServiceCategoryId)(serviceCategory.id);
  const sortedServices = services.sort((a, b) => (a.sort > b.sort ? 1 : -1));

  return (
    <div className={classNames(styles.container, isMenuListOpened && styles.isOpen)}>
      <div
        className={styles.typeHeaderContainer}
        onClick={() => setIsMenuListOpened(!isMenuListOpened)}
      >
        <H3 className={styles.typeHeader}>{serviceCategory.title}</H3>
        <div
          className={classNames(
            styles.arrow,
            isMenuListOpened ? styles.menuOpenedRotateIcon : styles.menuClosedRotateIcon
          )}
        />
      </div>
      <AnimateHeight
        className={styles.animateContainer}
        height={isMenuListOpened ? 'auto' : 0}
        duration={100}
      >
        {sortedServices.map((service: Service) => {
          return (
            <ServiceItem
              service={service}
              handleSelection={handleSelection}
              handleDeselection={handleDeselection}
              isMenuListOpened={isMenuListOpened}
              key={service.id}
            />
          );
        })}
      </AnimateHeight>
    </div>
  );
};

export default ServiceList;
