import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../../../../../../common/components/form/FormInput';
import { XCircle, InfoIcon } from '../../../../../../common/components/icons/Icons';
import { setPromocode } from '../../../../forces/actions';
import { getPromocode } from '../../../../forces/selectors';
import styles from './SummarySectionNew.module.css';

const PromoCodeSection = () => {
  const dispatch = useDispatch();
  const promoCode = useSelector(getPromocode);
  const [showInputField, setShowInputField] = useState(false);

  const handleToggle = () => {
    dispatch(setPromocode(null));
    setShowInputField((prev) => !prev);
  };

  const onChangePromocode = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromocode(e.target.value));
  };

  return (
    <div className={styles.promocodeContainer}>
      <div>
        <label
          htmlFor="change-rebooking-user"
          className={classNames(styles.underline, styles.iconRow)}
        >
          {showInputField ? <XCircle /> : <InfoIcon />}
          <button
            className={styles.strippedButton}
            id="change-rebooking-user"
            onClick={handleToggle}
          >
            {showInputField ? 'Fjern' : 'Legg til kampanjekode'}
          </button>
        </label>
      </div>
      {showInputField && (
        <>
          <FormInput
            className={styles.promocodeInput}
            defaultValue={''}
            value={promoCode}
            styleType="secondary"
            type="text"
            placeholder="Skriv inn kampanjekoden"
            onChange={onChangePromocode}
          />
          <p className={styles.smallText}>
            Bookingoversikten vil ikke reflektere brukt kampanjekode, men rabatten vil bli trukket
            fra i salong. Ved bruk av kampanjekode betaler man i salong
          </p>
        </>
      )}
    </div>
  );
};

export default PromoCodeSection;
