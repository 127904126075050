import * as React from 'react';
import ReactAnimateHeight from 'react-animate-height';

type Props = {
  height: number | 'auto';
  duration?: number;
} & React.HTMLAttributes<HTMLElement>;

const AnimateHeight: React.FunctionComponent<Props> = ({
  className,
  height,
  duration = 300,
  ...props
}) => {
  return (
    <ReactAnimateHeight easing="ease" duration={duration} height={height} className={className}>
      {props.children}
    </ReactAnimateHeight>
  );
};

export default AnimateHeight;
