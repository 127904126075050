import posthog from 'posthog-js';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import store from './common/store';
import { appConfig } from './config';
import { appSetup } from './domain/app/forces/actions';
import Router from './domain/app/Router';
import { ModalProvider } from './common/components/modal/ModalProvider';

store.dispatch<any>(appSetup());

const initPosthog = () => {
  if (process.env.NODE_ENV !== 'production') return;
  posthog.init('phc_tkalZsti5aIIhXPVXW86svKfHTYvBPsifT5iJN5we1H', {
    api_host: 'https://eu.posthog.com',
    autocapture: false,
  });
};

function App() {
  useEffect(() => {
    initPosthog();
    TagManager.initialize({
      gtmId: appConfig.gtm,
    });
  }, []);

  return (
    <Provider store={store}>
      <ModalProvider>
        <Router />
      </ModalProvider>
    </Provider>
  );
}

export default App;
