import { combineReducers } from 'redux';
import appReducer from './domain/app/forces/reducers';
import authReducer from './domain/auth/forces/reducers';
import bookingReducers from './domain/booking/forces/reducers';
import calenderReducers from './domain/booking/sections/calendarSection/forces/reducers';
import clinicReducers from './domain/booking/sections/clinicSection/forces/reducers';
import resourceReducers from './domain/booking/sections/resourceSection/forces/reducers';
import serviceReducers from './domain/booking/sections/serviceSection/forces/reducers';
import registerReducer from './domain/register/forces/reducers';
import userReducer from './domain/user/forces/reducers';

const reducers = combineReducers({
  app: appReducer,
  booking: bookingReducers,
  service: serviceReducers,
  clinic: clinicReducers,
  resource: resourceReducers,
  calendar: calenderReducers,
  user: userReducer,
  register: registerReducer,
  auth: authReducer,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
