import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import CallToActionButton from '../../../../common/components/callToAction/CallToActionButton';
import LoadingIndicator from '../../../../common/components/loadingIndicator/LoadingIndicator';
import { H2 } from '../../../../common/components/texts';
import { getFirstName } from '../../../../common/utils/formatting.utils';
import { setSelectedResource } from '../../forces/actions';
import {
  getSelectedClinic,
  getSelectedResource,
  getSelectedServices,
} from '../../forces/selectors';
import { BookingSectionIDs } from '../../forces/types';
import { fetchAvailability } from '../calendarSection/forces/actions';
import { getLoading } from '../calendarSection/forces/selectors';
import { fetchResources } from './forces/actions';
import { getResources, isLoading } from './forces/selectors';
import styles from './ResourceSection.module.css';
import { Resource } from './types';
import { trackBookingStep } from '../../../../common/analytics/posthog';

interface Props {
  resourceIds?: string[];
}

const RebookingResourceSection = ({ resourceIds }: Props) => {
  const isLoadingResources = useSelector(isLoading);
  const isLoadingAvailability = useSelector(getLoading);
  const selectedServices = useSelector(getSelectedServices);
  const selectedClinic = useSelector(getSelectedClinic);
  const resources = useSelector(getResources)(false);
  const selectedResource = useSelector(getSelectedResource);
  const showLoading = selectedServices && selectedClinic && isLoadingResources;
  const disableResourceButtons = isLoadingResources || isLoadingAvailability;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedClinic) {
      dispatch(fetchResources(selectedClinic.id, selectedServices));
    }
  }, [dispatch, selectedClinic, selectedServices]);

  useEffect(() => {
    if (resources) {
      const resource = resources.find((resource) => resource.id === resourceIds[0]);
      onSelectResource(resource);
    }
  }, [resources]);

  const onSelectResource = (resource: Resource | null) => {
    trackBookingStep('Velg behandler', { resourceId: resource?.id, resourceName: resource?.name });
    const isSelected = selectedResource === resource;
    if (isSelected) return;
    dispatch(setSelectedResource(resource));
    dispatch(fetchAvailability());
  };

  const renderSection = () => {
    if (showLoading || !resources)
      return <LoadingIndicator loadingText="Ser etter ledige behandlere" />;
    return (
      <div className={styles.resourcesContainer}>
        <CallToActionButton
          styleType={selectedResource ? 'outlinedQuaternary' : 'primary'}
          size="normal"
          className={classNames(styles.button, styles.buttonFullWidth)}
          onClick={() => onSelectResource(null)}
          disabled={disableResourceButtons}
        >
          Første ledige behandler
        </CallToActionButton>
        {resources.map((resource) => {
          const isSelected = selectedResource && selectedResource.id === resource.id;
          return (
            <CallToActionButton
              key={resource.id}
              styleType={isSelected ? 'primary' : 'outlinedQuaternary'}
              theme="light"
              size="normal"
              className={styles.button}
              onClick={() => onSelectResource(resource)}
              disabled={disableResourceButtons}
            >
              {getFirstName(resource.name)}
            </CallToActionButton>
          );
        })}
      </div>
    );
  };

  return (
    <Scroll.Element name={BookingSectionIDs.ResourceSection}>
      <section className={styles.container}>
        <H2 className={styles.title}>Behandler</H2>
        <div className={styles.divider} />
        {renderSection()}
      </section>
    </Scroll.Element>
  );
};

export default RebookingResourceSection;
