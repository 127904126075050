import * as React from 'react';
import classNames from 'classnames';
import Tooltip from '../tooltip/Tooltip';
import styles from './Infobox.module.css';
import { useModal } from '../modal/useModal';
import { useScreenWidth } from '../../hooks/useScreenWidth';

type Props = {
  description: string;
  isSelected: boolean;
  title: string;
} & React.HTMLAttributes<HTMLElement>;

const Infobox: React.FunctionComponent<Props> = ({
  isSelected,
  title,
  description,
  className,
  ...props
}) => {
  const { openModalWithContent } = useModal();
  const { isMobile } = useScreenWidth();

  const ModalContent = () => (
    <div className={styles.modalContent}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );

  return (
    <div
      className={classNames(
        styles.infoSymbol,
        {
          [styles.infoSymbolSelected]: isSelected,
        },
        className
      )}
    >
      {isMobile ? (
        <div
          className={styles.infoMobileMobile}
          onClick={() => openModalWithContent(<ModalContent />)}
        />
      ) : (
        <Tooltip className={styles.infoModal}>{description}</Tooltip>
      )}
    </div>
  );
};

export default Infobox;
