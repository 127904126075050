import classNames from 'classnames';
import * as React from 'react';
import { assertUnreachable } from '../../utils/ts.utils';
import styles from './FloatingLabel.module.css';

type CallToActionStyleType = 'primary' | 'secondary';
type LabelStyleType = 'primary' | 'secondary';
export type FormType = 'name' | 'tel' | 'email' | 'text' | 'date' | 'password';

type Props = {
  label?: string;
  labelStyleType?: LabelStyleType;
  value?: string;
  required?: boolean;
  styleType?: CallToActionStyleType;
  type?: FormType;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const getType = (type?: FormType) => {
  switch (type) {
    case 'name':
      return 'name';
    case 'tel':
      return 'tel';
    case 'email':
      return 'email';
    case 'date':
      return 'date';
    case 'password':
      return 'password';
    default:
      return 'text';
  }
};

const styleTypeToClassName = (styleType: CallToActionStyleType) => {
  switch (styleType) {
    case 'primary':
      return styles.inputPrimary;
    case 'secondary':
      return styles.inputSecondary;
    default:
      assertUnreachable(styleType);
  }
};

const labelStyleToClassName = (styleType: LabelStyleType) => {
  switch (styleType) {
    case 'primary':
      return styles.labelPrimary;
    case 'secondary':
      return styles.labelSecondary;
    default:
      return styles.labelPrimary;
  }
};

const FloatingLabel: React.FunctionComponent<Props> = ({
  className,
  children,
  required = true,
  styleType = 'primary',
  labelStyleType = 'primary',
  ...props
}) => {
  return (
    <div className={styles.container}>
      <input
        {...props}
        type={getType(props.type)}
        className={classNames(styles.input, styleTypeToClassName(styleType))}
        required={required}
      />
      <label className={classNames(styles.label, labelStyleToClassName(labelStyleType))}>
        {' '}
        {props.label}{' '}
      </label>
    </div>
  );
};

export default FloatingLabel;
