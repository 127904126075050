import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CallToActionButton from '../../../common/components/callToAction/CallToActionButton';
import FormAlert from '../../../common/components/form/FormAlert';
import FormInput from '../../../common/components/form/FormInput';
import { Container } from '../../../common/components/grid/Grid';
import Layout from '../../../common/components/layout/Layout';
import Link from '../../../common/components/link/Link';
import LoadingIndicator from '../../../common/components/loadingIndicator/LoadingIndicator';
import { H2 } from '../../../common/components/texts';
import { useSelector } from '../../../common/store/hooks';
import { parseQueryString } from '../../../common/utils/url.utils';
import Routes from '../../app/Routes';
import {
  resetPasswordResetFields,
  resetUserPassword,
  sendUserPasswordResetMail,
  setPasswordResetErrorMessage,
} from '../forces/actions';
import {
  getPasswordResetErrorMessage,
  getPasswordResetIsLoading,
  getPasswordResetSuccessMessage,
  getShowLoginButton,
} from '../forces/selectors';
import styles from './ForgotPassword.module.css';

const ForgotPassword = (pageContext: any) => {
  const errorMessage = useSelector(getPasswordResetErrorMessage);
  const successMessage = useSelector(getPasswordResetSuccessMessage);
  const isLoading = useSelector(getPasswordResetIsLoading);
  const showLoginButton = useSelector(getShowLoginButton);
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState<string>();
  const dispatch = useDispatch();

  const queryString = parseQueryString(pageContext.location.search);
  useEffect(() => {
    const userId = queryString.hashedId as string;
    if (userId) {
      setUserId(userId);
    }
    return () => {
      dispatch(resetPasswordResetFields());
    };
  }, [dispatch, queryString.hashedId]);

  const sendPasswordResetMail = async () => {
    if (!email) {
      return dispatch(setPasswordResetErrorMessage('Vennligst fyll inn e-post.'));
    }
    dispatch(sendUserPasswordResetMail(email));
  };

  const changePassword = async () => {
    const hasMinimumCharactersNeeded = newPassword.length >= 8;
    if (newPassword.length === 0 || repeatNewPassword.length === 0) {
      return dispatch(setPasswordResetErrorMessage('Vennligst fyll inn begge feltene.'));
    }

    if (newPassword !== repeatNewPassword) {
      return dispatch(setPasswordResetErrorMessage('Begge passordene må være like.'));
    }

    if (!hasMinimumCharactersNeeded) {
      return dispatch(setPasswordResetErrorMessage('Passordet ditt må være på minst 8 tegn.'));
    }

    userId && dispatch(resetUserPassword(userId, newPassword));
  };

  const renderGetResetLinkInMail = () => {
    return (
      <Container>
        <H2>Glemt passord</H2>
        <FormInput
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
          placeholder="Epost"
          styleType="secondary"
          type="email"
          required
        />
        <div className={styles.buttonContainer}>
          <CallToActionButton type="submit" onClick={sendPasswordResetMail}>
            Neste
          </CallToActionButton>
        </div>
      </Container>
    );
  };

  const renderConfirmPasswordChange = () => {
    return (
      <Container>
        <H2>Oppdater passord</H2>
        <FormInput
          value={newPassword}
          type="password"
          onChange={(evt) => setNewPassword(evt.target.value)}
          placeholder="Nytt passord"
          styleType="secondary"
          required
        />
        <FormInput
          value={repeatNewPassword}
          type="password"
          onChange={(evt) => setRepeatNewPassword(evt.target.value)}
          placeholder="Gjenta passord"
          styleType="secondary"
          required
        />
        <div className={styles.buttonContainer}>
          <CallToActionButton type="submit" onClick={changePassword}>
            Bekreft
          </CallToActionButton>
        </div>
      </Container>
    );
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {userId ? <> {renderConfirmPasswordChange()} </> : <>{renderGetResetLinkInMail()}</>}
          <div className={styles.alert}>
            {errorMessage || successMessage ? (
              <FormAlert error={errorMessage} success={successMessage} />
            ) : null}
          </div>
          {isLoading && <LoadingIndicator />}
          {showLoginButton && (
            <Link to={Routes.LogIn()}>
              <CallToActionButton type="button" styleType="secondary">
                Login
              </CallToActionButton>
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
