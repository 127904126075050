import { createReducer } from 'deox';
import { loginSuccess } from '../../user/forces/actions';
import { loginError, loginRequest } from './actions';

interface State {
  error: Error | null;
  isLoading: boolean;
}

const defaultState: State = {
  error: null,
  isLoading: false,
};

const loginReducer = createReducer(defaultState, (handle) => [
  handle(loginRequest, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  handle(loginSuccess, (state) => ({
    ...state,
    error: null,
    isLoading: false,
  })),
  handle(loginError, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload.error,
  })),
]);

export default loginReducer;
