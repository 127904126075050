import { Link as ReachRouterLink } from '@reach/router';
import classNames from 'classnames';
import * as React from 'react';
import Routes from '../../../domain/app/Routes';
import styles from './Link.module.css';

type Props = {
  className?: string;
  underlined?: boolean;
  type?: 'primary' | 'secondary' | 'tertiary';
  to?: string;
  children?: React.ReactNode;
  onClick?: (evt: any) => void;
};

const Link: React.FunctionComponent<Props> = ({
  className,
  underlined,
  type = 'primary',
  to = Routes.Main(),
  children,
  onClick,
}) => {
  return (
    <ReachRouterLink
      to={to}
      className={classNames(
        styles.link,
        { [styles.linkUnderlined]: underlined },
        styles[type],
        className
      )}
      onClick={onClick}
    >
      {children}
    </ReachRouterLink>
  );
};

export default Link;
