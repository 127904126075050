import { stringifyObjectWithBrackets } from '../../../../../common/utils/url.utils';
import { APIRequest } from '../../../../../root.types';
import { CombinedResources } from './actions';

const fetchResources = (request: APIRequest) => async (clinicId: string, serviceIds?: string[]) => {
  const serviceQueryParameters = stringifyObjectWithBrackets({
    serviceIds,
  });
  return request<CombinedResources>({
    path: `/clinics/${clinicId}/resources?${serviceQueryParameters}`,
  });
};

export default {
  fetchResources,
};
