import { AnyAction, createAction } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import storage from '../../../common/utils/storage.utils';
import { RootState } from '../../../root.reducer';
import { ThunkArguments } from '../../../root.types';
import { fetchCurrentUser, setUserToken } from '../../user/forces/actions';
import api from '../../user/forces/api';
import { getCurrentUser } from '../../user/forces/selectors';
import { RegistrationStep } from '../../user/forces/types';

const tokenKey = 'olio_token';

export const registerUserRequest = createAction('REGISTER_CREATE_USER_REQUEST');
export const registerUserError = createAction(
  'REGISTER_CREATE_USER_ERROR',
  (handler) => (error: Error) => {
    return handler({ error });
  }
);
export const registerUserSuccess = createAction('REGISTER_CREATE_USER_SUCCESS');
export const setCurrentStep = createAction(
  'REGISTER_SET_CURRENT_STEP',
  (handler) => (currentStep: RegistrationStep) => {
    return handler({ currentStep });
  }
);
export const setReferralCode = createAction(
  'REGISTER_SET_REFERRAL_CODE',
  (handler) => (referralCode: string) => {
    return handler({ referralCode });
  }
);

export const storeToken = (token: string) => storage.setItem(tokenKey, token);
export const getStoredToken = () => storage.getItem(tokenKey);
export const removeStoredToken = () => storage.removeItem(tokenKey);

export const registerUser = (params: {
  name: string;
  email: string;
  phoneNumber: string;
  password: string;
  referralCode: string;
}) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    try {
      dispatch(registerUserRequest());
      const createUserResponse = await api.createUser(request)(params);
      storeToken(createUserResponse.token);
      dispatch(setUserToken(createUserResponse.token));
      dispatch(setCurrentStep(RegistrationStep.Summary));
      await dispatch(fetchCurrentUser());
      const user = getCurrentUser(getState());
      const trackData = { user };
      dispatch(newMemberRegistered(trackData));
      return createUserResponse;
    } catch (err) {
      const error = err as Error;
      dispatch(registerUserError(error));
    }
  };
};

export const newMemberRegistered = createAction(
  'NEW_MEMBER_REGISTERED',
  (resolve) => (trackData: any) => {
    return resolve({ trackData });
  }
);

export const resetRegisterForm = createAction('REGISTER_RESET_FORM');
