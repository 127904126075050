import { useSelector } from 'react-redux';
import { Service } from '../../../../../../root.types';
import {
  getEditMode,
  getGuestInfo,
  getIsBookingValid,
  getTotalDuration,
} from '../../../../forces/selectors';
import { Clinic as ClinicType } from '../../../clinicSection/forces/types';
import { Resource as ResourceType } from '../../../resourceSection/types';
import styles from './SummarySectionNew.module.css';
import { getFilteredServices } from '../../../../forces/booking.utils';
import { useState } from 'react';
import OlioPoints from '../OlioPoints';
import { useDispatch } from '../../../../../../common/store/hooks';
import {
  checkIfUserWithMemberRoleExists,
  createBooking,
  updateBooking,
} from '../../../../forces/actions';
import { getCurrentUser } from '../../../../../user/forces/selectors';
import { RootState } from '../../../../../../root.reducer';
import CreateBookingSection from './CreateBookingSection';
import BookingDetails from './BookingDetails';
import InvalidSections from './InvalidSections';
import PromoCodeSection from './Promocode';
import ServicesOverview from './Services';
import EditBookingSection from './EditBookingSection';
import CancelBookingSection from './CancelBookingSection';

interface Props {
  services: Service[];
  clinic: ClinicType;
  resource: ResourceType;
  time: string;
  appliedDiscountPercentage?: number;
  isEditMode?: boolean;
  showPayWithOlioPoints?: boolean;
  showBookButton?: boolean;
  showAddPromoCode?: boolean;
  showCancelButton?: boolean;
}

const BookingOverviewNew = ({
  services,
  clinic,
  resource,
  time,
  appliedDiscountPercentage,
  showPayWithOlioPoints,
  showBookButton,
  showAddPromoCode,
  isEditMode,
  showCancelButton,
}: Props) => {
  const { filteredServices } = getFilteredServices(services);
  const totalDuration = useSelector(getTotalDuration)(filteredServices); // TODO: Should this just be filteredServices?

  if (!services) {
    return null;
  }

  return (
    <div className={styles.bookingOverviewSection}>
      <div className={styles.bookingOverviewContainer}>
        <BookingDetails
          time={time}
          totalDuration={totalDuration}
          clinicName={clinic?.name}
          clinicAddress={clinic?.address.streetAddress}
          resourceName={resource?.name}
        />
        <div className={styles.servicesWithPromocode}>
          <ServicesOverview
            services={services}
            appliedDiscountPercentage={appliedDiscountPercentage}
          />
          {showAddPromoCode && !isEditMode && <PromoCodeSection />}
        </div>
        {showPayWithOlioPoints && <OlioPoints services={filteredServices} />}
        {showBookButton && (
          <>
            <BookingSection />
            <InvalidSections />
          </>
        )}
      </div>
      {isEditMode && showCancelButton && <CancelBookingSection />}
    </div>
  );
};

const BookingSection = () => {
  const isEditMode = useSelector(getEditMode);
  const dispatch = useDispatch();
  const [hasCheckedUser, setHasCheckedUser] = useState(false);
  const guestUserInfo = useSelector(getGuestInfo);
  const user = useSelector(getCurrentUser);

  const isBookingLoading = useSelector((state: RootState) => state.booking.isLoading);
  const isBookingValid = useSelector(getIsBookingValid);
  const isDisabled = !isBookingValid || isBookingLoading;

  const handleCreateBooking = () => {
    const shouldCheckIfUserWithMemberRoleExists = !user && !hasCheckedUser;
    if (shouldCheckIfUserWithMemberRoleExists) {
      setHasCheckedUser(true);
      // TODO: This should not dispatch another createBooking. Should return a boolean
      return dispatch(checkIfUserWithMemberRoleExists(guestUserInfo.phoneNumber));
    }
    dispatch(createBooking());
  };

  const handleUpdateBooking = () => {
    dispatch(updateBooking());
  };

  if (isEditMode) {
    return <EditBookingSection handleEditBooking={handleUpdateBooking} isDisabled={isDisabled} />;
  }

  return <CreateBookingSection handleCreateBooking={handleCreateBooking} isDisabled={isDisabled} />;
};

export default BookingOverviewNew;
