import * as React from 'react';
import { Squeeze } from 'react-burgers';

type Props = {
  active?: boolean;
  color?: string;
  lineHeight?: number;
} & React.HTMLAttributes<HTMLElement>;

const HamburgerMenu = ({ active = false, lineHeight = 4, ...props }: Props) => {
  return (
    <Squeeze
      lineHeight={lineHeight}
      padding={0}
      color={active ? '#f5634c' : '#fff'}
      active={active}
      {...props}
    />
  );
};

export default HamburgerMenu;
