import * as React from 'react';
import {
  addToCalendar,
  CalendarType,
  ICalendarEvent,
} from '@askeladden/utils/dist/utils/calendar.utils';
import { Column, Row } from '../../../common/components/grid/Grid';
import CallToActionButton from '../../../common/components/callToAction/CallToActionButton';
import googleCalIcon from '../../../assets/images/google_icon.svg';
import outlookCalIcon from '../../../assets/images/outlook_icon.svg';
import styles from './BookingAddToCal.module.css';

type Props = {
  eventDetails: ICalendarEvent;
};

const BookingAddToCalSection: React.FunctionComponent<Props> = ({ eventDetails }: Props) => {
  const [showCalendarButtons, setShowCalendarButtons] = React.useState(false);

  return (
    <Row className={styles.calendarWrapper}>
      <Column xs={12} md={12} lg={12}>
        <CallToActionButton
          styleType="primary"
          theme="light"
          onClick={() => setShowCalendarButtons(!showCalendarButtons)}
          className={styles.homeButton}
        >
          Legg til i kalender
        </CallToActionButton>
        {showCalendarButtons && (
          <Row>
            <Column xs={12} md={12} lg={12}>
              <img
                className={styles.calendarIconWrappers}
                src={googleCalIcon}
                onClick={() => addToCalendar(eventDetails, CalendarType.GOOGLE)}
              />
              <img
                className={styles.calendarIconWrappers}
                src={outlookCalIcon}
                onClick={() => addToCalendar(eventDetails, CalendarType.OUTLOOK)}
              />
            </Column>
          </Row>
        )}
      </Column>
    </Row>
  );
};

export default BookingAddToCalSection;
