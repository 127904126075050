import { removeDuplicatesByProperty } from '../../../../../common/utils/array.utils';
import { RootState } from '../../../../../root.reducer';
import { Service, ServiceCategory } from '../../../../../root.types';
import { calculateTotalDuration } from './service.utils';

export const getTotalDuration = (services: Array<Service>) => (state: RootState) => {
  return calculateTotalDuration(services);
};

export const getServices = (state: RootState) => state.service.services;

export const getServicesByIds = (state: RootState) => (serviceIds: string[]) => {
  if (!serviceIds || !state.service.services) return;
  return state.service.services.filter((service) =>
    serviceIds.includes(service.waitwhileServiceId)
  );
};

export const getServicesByIdsWithChildrenAndPackageParent =
  (state: RootState) => (serviceIds: string[]) => {
    const services = state.service.services ?? [];

    if (!serviceIds || !services) return;
    const additionalServices = services
      .filter((service) => service.additionalServices.length > 0)
      .map((service) => service.additionalServices)
      .flat();
    const parentOfChosenPackages = additionalServices
      .filter(
        (additionalService) =>
          additionalService.isPackageDeal &&
          serviceIds.includes(additionalService.waitwhileServiceId)
      )
      .map((additionalService) => additionalService.parentServiceId);
    const serviceIdsWithParentIds = [...serviceIds, ...parentOfChosenPackages];
    const combinedServiceArray = removeDuplicatesByProperty(
      [...services, ...additionalServices],
      'id'
    );
    const selectedServices = combinedServiceArray.filter(
      (service) =>
        serviceIdsWithParentIds.includes(service.id) ||
        serviceIdsWithParentIds.includes(service.waitwhileServiceId)
    );
    return selectedServices;
  };

export const getServiceCategories = (state: RootState): ServiceCategory[] => {
  if (!state.service.services) return;
  const serviceCategories: ServiceCategory[] = [];
  state.service.services.map((service) => {
    if (!service.serviceCategory) return;
    const isExist = serviceCategories.find(
      (serviceCategory) => serviceCategory.id === service.serviceCategory.id
    );
    if (!isExist) {
      serviceCategories.push(service.serviceCategory);
    }
  });
  return serviceCategories;
};

export const getServicesByServiceCategoryId = (state: RootState) => (serviceCategoryId: string) => {
  return state.service.services.filter(
    (service) => service.serviceCategory && service.serviceCategory.id === serviceCategoryId
  );
};

export const getActiveServicesByServiceCategoryId =
  (state: RootState) => (serviceCategoryId: string) => {
    const services = getServicesByServiceCategoryId(state)(serviceCategoryId);
    return services.filter(
      (service) => !(service.isHidden || service.isArchived || service.isDraft)
    );
  };

export const getFetchServicesError = (state: RootState) => state.service.error;
