import { ICalendarEvent } from '@askeladden/utils/dist/utils/calendar.utils';
import { navigate, RouteComponentProps } from '@reach/router';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Container } from '../../../common/components/grid/Grid';
import Layout from '../../../common/components/layout/Layout';
import { BodyText, H2, H4 } from '../../../common/components/texts';
import { formatIsoToDateAndYear, formatIsoToTime } from '../../../common/utils/date.utils';
import Routes from '../../app/Routes';
import { getCurrentUser } from '../../user/forces/selectors';
import { resetBookingForm } from '../forces/actions';
import { getEditMode } from '../forces/selectors';
import BookingAddToCalSection from './BookingAddToCal';
import styles from './BookingSuccess.module.css';
import BookingSuccessMemberGuestSection from './BookingSuccessMemberGuestSection';
import { getFilteredServices } from '../forces/booking.utils';

type Props = RouteComponentProps & React.HTMLAttributes<HTMLElement>;

const BookingSuccess = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBookingForm());
  }, []);

  if (!props.location.state) {
    window.location.href = Routes.Main();
    return null;
  }

  const editMode = useSelector(getEditMode);
  const user = useSelector(getCurrentUser);

  const { selectedServices, selectedClinic, selectedTime } = props.location.state;
  if (!selectedServices && !selectedClinic && !selectedTime) {
    navigate(Routes.Main());
    return null;
  }
  const { filteredServices } = getFilteredServices(selectedServices);
  const date = formatIsoToDateAndYear(selectedTime);
  const time = formatIsoToTime(selectedTime);
  const location = selectedClinic
    ? `${selectedClinic.name} (${selectedClinic.address.streetAddress})`
    : '';
  const duration = filteredServices
    .map((service) => service.duration)
    .reduce((totalDuration, currentDuration) => {
      return totalDuration + currentDuration;
    }, 0);
  const welcomeText = `Vi gleder oss til å ta deg imot ${date} klokken ${time} på ${location}`;
  const bookingUpdateText = 'Bookingen din er oppdatert.';

  const eventDetails: ICalendarEvent = {
    title: 'Time hos Olio',
    description: welcomeText,
    location: selectedClinic?.address.streetAddress || 'Olio',
    startTime: dayjs(selectedTime).toISOString(),
    endTime: dayjs(selectedTime).add(duration, 'm').toISOString(),
  };

  return (
    <Layout className={styles.container}>
      <Container>
        <Column xs={12} md={6} mdOffset={3} lg={6} lgOffset={3}>
          <H2 className={styles.title}>
            {'Tusen takk!'}
            <br />
            {editMode && bookingUpdateText}
          </H2>
          <BodyText>{welcomeText}</BodyText>
          <BookingSuccessMemberGuestSection user={user} />
          <div className={styles.noshowBanner}>
            <p className={styles.bannerText}>
              Manglende oppmøte til time, eller time som blir avbestilt mindre enn 24 timer før, vil
              bli fakturert i sin helhet.
            </p>
          </div>
          <BookingAddToCalSection eventDetails={eventDetails} />
        </Column>
      </Container>
    </Layout>
  );
};

export default BookingSuccess;
