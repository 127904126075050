export const replaceParameters = (path: string, parameters: { [key: string]: string }) => {
  return Object.keys(parameters).reduce((currentValue, key) => {
    return currentValue.replace(`:${key}`, parameters[key]);
  }, path);
};

const whitelistedPrivateRoutes = ['/booking/update'];

export const isPathNameWhitelistedForGuests = (pathname: string) => {
  return whitelistedPrivateRoutes.filter((path) => pathname.includes(path)).length > 0;
};
