import * as React from 'react';
import classNames from 'classnames';
import { BodyBolderText, H3 } from '../texts';
import styles from './SectionListTitle.module.css';

type Props = {
  size?: 'normal' | 'big';
} & React.HTMLAttributes<HTMLElement>;

const SectionListTitle: React.FunctionComponent<Props> = ({
  className,
  size = 'normal',
  children,
  ...props
}) => {
  const Component = size === 'big' ? H3 : BodyBolderText;
  return (
    <Component className={classNames(styles.text, className)} {...props}>
      {children}
    </Component>
  );
};

export default SectionListTitle;
