export const getFirstName = (fullName: string) => {
  const parts = fullName.split(' ');
  return parts.length === 0 ? fullName : parts[0];
};

export const splitFirstAndLastName = (name: string) => {
  let firstName;
  let lastName;
  if (name.indexOf(' ') === -1) {
    firstName = name;
    lastName = '';
  } else {
    const splittedName = name.split(' ');
    firstName = splittedName.slice(0, -1).join(' ');
    lastName = splittedName.slice(-1).join(' ');
  }
  return { firstName, lastName };
};
