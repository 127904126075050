import * as React from 'react';
import classNames from 'classnames';
import styles from './Tooltip.module.css';

type Props = {} & React.HTMLAttributes<HTMLElement>;

const Tooltip: React.FunctionComponent<Props> = ({ className, ...props }) => {
  return (
    <div className={classNames(styles.infoModal, className)} {...props}>
      {props.children}
    </div>
  );
};

export default Tooltip;
