import { Day, DayRenderProps } from '@askeladden/availability-ui';
import classNames from 'classnames';
import { AvailableDate } from '../forces/types';
import styles from './DayPicker.module.css';

const DayPicker = (day: DayRenderProps & Day & AvailableDate) => {
  const determineStyling = () => {
    if (day.isSelected && !day.isAvailable) {
      return styles.unavailableSelected;
    }
    if (day.isSelected) {
      return styles.selected;
    }
    if (!day.isAvailable) {
      return styles.unavailable;
    }
    if (!day.isSelected) {
      return styles.unselected;
    }
  };
  const cssClassName = classNames(styles.button, determineStyling());
  const monthNameFormatted = day.formatted.monthName;
  const monthText =
    monthNameFormatted.length > 5 ? monthNameFormatted.substring(0, 3) : monthNameFormatted;
  return (
    <button className={cssClassName}>
      <div>{day.formatted.shortDayName}</div>
      <div className={styles.dateText}>{day.formatted.date}</div>
      <div>{monthText}</div>
    </button>
  );
};

export default DayPicker;
