import { BodyBolderText } from '../../../../../common/components/texts';
import { formatToNOK } from '../../../../../common/utils/currency.utils';
import styles from '../SummarySection.module.css';

interface Props {
  originalPrice: number;
  discountedPrice?: number;
}

const ServicePrice = ({ originalPrice, discountedPrice }: Props) => {
  const isDiscountedPrice = discountedPrice && originalPrice !== discountedPrice;
  const totalPrice = discountedPrice ? discountedPrice : originalPrice;
  const formattedPriceInNOK = formatToNOK(totalPrice, true);

  return (
    <>
      <div className={styles.priceWrapper}>
        {!!isDiscountedPrice && (
          <div className={styles.discountWrapper}>
            <BodyBolderText className={styles.servicePrice}>{originalPrice}</BodyBolderText>
          </div>
        )}
        <BodyBolderText>{formattedPriceInNOK}</BodyBolderText>
      </div>
    </>
  );
};

export default ServicePrice;
