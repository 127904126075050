import { navigate } from '@reach/router';
import { createAction } from 'deox';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import ErrorTracker from '../../../common/errorTracking/ErrorTracker';
import {
  formatToAPIDate,
  formatToDateAndYear,
  formatToTime,
} from '../../../common/utils/date.utils';
import { RootState } from '../../../root.reducer';
import { Service, ThunkArguments } from '../../../root.types';
import Routes from '../../app/Routes';
import { createGuest } from '../../auth/forces/actions';
import { fetchCurrentUser, fetchCurrentUserSuccess } from '../../user/forces/actions';
import { mapToUser } from '../../user/forces/mapper';
import { getBookingById, getCurrentUser, getToken } from '../../user/forces/selectors';
import { Role } from '../../user/forces/types';
import { fetchAvailability } from '../sections/calendarSection/forces/actions';
import { Clinic } from '../sections/clinicSection/forces/types';
import { getResourceById } from '../sections/resourceSection/forces/selectors';
import { Resource } from '../sections/resourceSection/types';
import {
  getServicesByIds,
  getServicesByIdsWithChildrenAndPackageParent,
} from '../sections/serviceSection/forces/selectors';
import { removeDeselectedService } from '../sections/serviceSection/forces/service.utils';
import * as api from './api';
import { getFilteredServices, scrollToBookingSection } from './booking.utils';
import {
  getClinicById,
  getGuestInfo,
  getPromocode,
  getSelectedClinic,
  getSelectedDate,
  getSelectedResource,
  getSelectedServices,
  getSelectedSlot,
  getSelectedTime,
  getTotalPrice,
  getUseOlioPoints,
} from './selectors';
import { Booking, BookingContext, BookingSectionIDs } from './types';
import posthog from 'posthog-js';
import { RebookingUser } from '../hooks/useUserByBookingId';
import { fetchResources } from '../sections/resourceSection/forces/actions';
import { Slot } from '../sections/calendarSection/forces/types';
import { acoBookingToWwClinicId, roomResourceIds } from '../../../common/utils/acobooking.utils';
import { sampleRandom } from 'common/utils/array.utils';

export const selectService = createAction(
  'BOOKING_SELECT_SERVICE',
  (resolve) => (service: Service) => {
    return resolve({ service });
  }
);

export const setSelectedServices = createAction(
  'BOOKING_SET_SELECTED_SERVICES',
  (resolve) => (services: Service[]) => {
    return resolve({ services });
  }
);

export const selectDate = createAction('BOOKING_SELECT_DATE', (resolve) => (date: string) => {
  return resolve({ date });
});

export const selectTime = createAction('BOOKING_SELECT_TIME', (resolve) => (time: string) => {
  return resolve({ time });
});

export const selectSlot = createAction('BOOKING_SELECT_SLOT', (resolve) => (slot: Slot) => {
  return resolve({ slot });
});

export const setIsBookingLoading = createAction(
  'BOOKING_SET_LOADING',
  (resolve) => (isLoading: boolean) => {
    return resolve({ isLoading });
  }
);

export const changeCalendarRange = createAction(
  'BOOKING_CHANGE_CALENDAR_RANGE',
  (resolve) => (options: { from: string; to: string; appointmentId?: string }) => {
    return resolve(options);
  }
);

export const setSelectedClinic = createAction(
  'BOOKING_SET_SELECTED_CLINIC',
  (resolve) => (clinic: Clinic | null) => {
    return resolve({
      clinic,
    });
  }
);

export const setSelectedResource = createAction(
  'BOOKING_SET_SELECTED_RESOURCE',
  (resolve) => (resource: Resource | null) => {
    return resolve({ resource });
  }
);

export const updateGuestInfo = createAction(
  'BOOKING_UPDATE_GUEST_INFO',
  (resolve) => (values: { [key: string]: string }) => {
    return resolve({ ...values });
  }
);

export const setUseOlioPoints = createAction(
  'BOOKING_SET_USE_OLIO_POINTS',
  (handler) => (useOlioPoints: boolean) => {
    return handler({ useOlioPoints });
  }
);

export const setBookingContext = createAction(
  'BOOKING_SET_BOOKING_CONTEXT',
  (resolve) => (bookingContext: BookingContext) => {
    return resolve({ bookingContext });
  }
);

export const setBookingId = createAction('SET_BOOKING_ID', (resolve) => (id: string) => {
  return resolve({ id });
});

export const prePopulateExistingBooking = createAction(
  'BOOKING_PRE_POPULATE_EXISTING_BOOKING',
  (resolve) =>
    (services: Service[], clinic: Clinic, date: string, time: string, bookingId: string) => {
      return resolve({
        services,
        clinic,
        date,
        time,
        bookingId,
      });
    }
);

export const prePopulateRebooking = createAction(
  'BOOKING_PRE_POPULATE_REBOOKING',
  (resolve) => (services: Service[], clinic: Clinic, bookingId: string, resource: Resource) => {
    return resolve({
      services,
      clinic,
      bookingId,
      resource,
    });
  }
);

export const setPromocode = createAction('SET_PROMOCODE', (resolve) => (promocode: string) => {
  return resolve({ promocode });
});

export const resetBookingForm = createAction('BOOKING_RESET_FORM');

export const setShowLoginOption = createAction(
  'BOOKING_SET_SHOW_LOGIN_OPTION',
  (resolve) => (showLoginOption: boolean) => {
    return resolve({ showLoginOption });
  }
);

export const fetchBookingsRequest = createAction('BOOKINGS_GET_REQUEST');

export const fetchBookingsSuccess = createAction(
  'BOOKINGS_GET_SUCCESS',
  (handler) => (bookings: Array<Booking>) => {
    return handler({ bookings });
  }
);
export const fetchBookingsError = createAction(
  'BOOKINGS_GET_ERROR',
  (handler) => (error: Error) => {
    return handler({ error });
  }
);

export const fetchBookingRequest = createAction('BOOKING_FETCH_REQUEST');

export const fetchBookingSuccess = createAction(
  'BOOKING_FETCH_SUCCESS',
  (handler) => (booking: Booking) => {
    return handler({ booking });
  }
);
export const fetchBookingError = createAction(
  'BOOKING_FETCH_ERROR',
  (handler) => (error: Error) => {
    return handler({ error });
  }
);

export const cancelBookingRequest = createAction('BOOKING_CANCEL_REQUEST');

export const cancelBookingSuccess = createAction(
  'BOOKING_CANCEL_SUCCESS',
  (handler) => (bookingId: string) => {
    return handler({ bookingId });
  }
);

export const cancelBookingError = createAction(
  'BOOKING_CANCEL_ERROR',
  (handler) => (error: Error) => {
    return handler({ error });
  }
);

export const createBookingRequest = createAction('BOOKING_CREATE_BOOKING_REQUEST');

export const createBookingSuccess = createAction(
  'BOOKING_CREATE_BOOKING_SUCCESS',
  (resolve) => (services: Service[], trackData: any) => {
    return resolve({ services, trackData });
  }
);

export const createBookingError = createAction(
  'BOOKING_CREATE_BOOKING_ERROR',
  (resolve) => (error: Error) => {
    return resolve({ error });
  }
);

export const resetBookingError = createAction('BOOKING_RESET_ERROR');

export const updateBookingRequest = createAction('BOOKING_UPDATE_BOOKING_REQUEST');

export const updateBookingSuccess = createAction('BOOKING_UPDATE_BOOKING_SUCCESS');

export const updateBookingError = createAction(
  'BOOKING_UPDATE_BOOKING_ERROR',
  (resolve) => (error: Error) => {
    return resolve({ error });
  }
);

export const rebookingUserPromptIsSubmitted = createAction(
  'BOOKING_REBOOKING_USER_PROMPT_SUBMITTED',
  (resolve) => (isSubmitted: boolean) => {
    return resolve({ isSubmitted });
  }
);

export const setRebookingUser = createAction(
  'BOOKING_SET_REBOOKING_USER',
  (resolve) => (rebookingUser: RebookingUser) => {
    return resolve({ rebookingUser });
  }
);

export const resetBookingDelete = createAction('BOOKING_RESET_BOOKING_DELETE');

export const validateAndScrollBookingSections = () => {
  return (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState
  ) => {
    const state = getState();
    const selectedServices = getSelectedServices(state);
    const selectedClinic = getSelectedClinic(state);
    const selectedDate = getSelectedDate(state);
    const selectedTime = getSelectedTime(state);
    const currentUser = getCurrentUser(state);
    if (selectedServices && selectedClinic && selectedDate && selectedTime && !currentUser) {
      return scrollToBookingSection(BookingSectionIDs.GuestSection);
    }
    if (selectedServices && selectedClinic && selectedDate && selectedTime)
      return scrollToBookingSection(BookingSectionIDs.CreateUpdateDeleteBooking);
    if (selectedServices && selectedClinic)
      return scrollToBookingSection(BookingSectionIDs.ResourceSection);
    // if (selectedServices) return scrollToBookingSection(BookingSectionIDs.ClinicSection);
    // return scrollToBookingSection(BookingSectionIDs.ServiceSelection);
  };
};

export const getBookingToken = () => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState
  ) => {
    const state = getState();
    const user = getCurrentUser(state);

    // Create a guest user if the user is not logged in
    if (!user) {
      const { name, phoneNumber, email } = getGuestInfo(state);
      const { token } = await dispatch(createGuest(name, phoneNumber, email));
      return token;
    }
    const token = getToken(state);
    return token;
  };
};

export const deselectService = (deselectedService: Service) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState
  ) => {
    const state = getState();
    const selectedServices = getSelectedServices(state) ?? [];
    const selectedServicesWithoutDeselected = removeDeselectedService(
      selectedServices,
      deselectedService
    );
    dispatch(setSelectedServices(selectedServicesWithoutDeselected));
    const lastServiceWasRemoved = selectedServicesWithoutDeselected.length === 0;
    const selectedClinic = getSelectedClinic(state);
    if (!lastServiceWasRemoved && selectedClinic) {
      dispatch(fetchAvailability());
    }
  };
};

export const deselectServiceById = (serviceId: string) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState
  ) => {
    const state = getState();
    const selectedServices = getSelectedServices(state);
    const deselectedService = selectedServices.find((service) => service.id === serviceId);
    if (!deselectedService) return;
    dispatch(deselectService(deselectedService));
  };
};

export const populateBookingWithExistingBooking = (clinicId: string, bookingId: string) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request, config, routes }: ThunkArguments
  ) => {
    await dispatch(fetchBookingIfNeeded(clinicId, bookingId));
    const state = getState();
    const booking = getBookingById(state)(bookingId);
    const services = getServicesByIdsWithChildrenAndPackageParent(state)(booking.serviceIds);
    const clinic = getClinicById(state)(clinicId);
    const date = formatToDateAndYear(booking.time);
    const time = formatToTime(booking.time);
    const currentUser = getCurrentUser(state);
    const isGuest = currentUser && currentUser.role === Role.Guest;
    const isValidBooking = booking && services && clinic;
    if (!isValidBooking) return;
    dispatch(prePopulateExistingBooking(services, clinic, date, time, booking.id));
    if (isGuest) {
      dispatch(
        updateGuestInfo({
          name: currentUser.name,
          phoneNumber: currentUser.phoneNumber,
          email: currentUser.email,
        })
      );
    }
    dispatch(fetchAvailability());
  };
};

export const populateRebooking = (
  clinicId: string,
  bookingId: string,
  serviceIds: string[],
  resourceIds: string[]
) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request, config, routes }: ThunkArguments
  ) => {
    let state = getState();
    const services = getServicesByIdsWithChildrenAndPackageParent(state)(serviceIds);
    let clinic = getClinicById(state)(clinicId);
    if (!clinic) {
      const mappedClinicId = acoBookingToWwClinicId(clinicId);
      clinic = getClinicById(state)(mappedClinicId);
    }
    await dispatch(fetchResources(clinicId, services));
    state = getState();

    const resources = resourceIds.map((resourceId) => getResourceById(state)(resourceId, true));
    const resource = resources.find((r) => r && !roomResourceIds.includes(r.id)); // FIXME: Temp solution to get the first primary resource

    const isValidBooking = services && clinic && resource;
    if (!isValidBooking) return;

    dispatch(prePopulateRebooking(services, clinic, bookingId, resource));
    dispatch(fetchAvailability());
  };
};

export const checkIfUserWithMemberRoleExists = (phoneNumber: string) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    dispatch(setIsBookingLoading(true));
    const token = await dispatch(getBookingToken());
    if (!token) {
      throw new Error('token is not set');
    }
    try {
      const response = await api.isUserWithMemberRoleExists(request)(token, phoneNumber);
      const { isUserWithMemberRoleExists } = response;
      dispatch(setShowLoginOption(isUserWithMemberRoleExists));
      if (!isUserWithMemberRoleExists) dispatch(createBooking());
    } catch (err) {
      dispatch(createBookingError(new Error('Booking creation error')));
      dispatch(setIsBookingLoading(false));
    }
  };
};

export const fetchBookingIfNeeded = (clinicId: string, bookingId: string) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    const state = getState();
    const booking = getBookingById(state)(bookingId);
    if (booking != null) {
      console.log('Booking exists in state, so return');
      // Booking exists in state, so return
      return;
    }

    const token = getToken(state);
    if (token == null) {
      throw new Error('token is not set');
    }

    try {
      dispatch(fetchBookingRequest());
      const booking = await api.fetchBooking(request)({ token, clinicId, bookingId });
      dispatch(fetchBookingSuccess(booking));
      return booking;
    } catch (err) {
      dispatch(fetchBookingError(err));
    }
  };
};

export const createRebooking = ({
  rebookingUserid,
  isMemberBookedAsGuest,
}: {
  rebookingUserid: string;
  isMemberBookedAsGuest: boolean;
}) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request, config, routes }: ThunkArguments
  ) => {
    dispatch(createBookingRequest());
    dispatch(setIsBookingLoading(true));
    const state = getState();
    posthog.capture('Rebooking påbegynt');
    try {
      const selectedServices = getSelectedServices(state);
      const selectedClinic = getSelectedClinic(state);
      const selectedResource = getSelectedResource(state);
      const selectedDate = getSelectedDate(state);
      const selectedTime = getSelectedTime(state);
      const useOlioPoints = getUseOlioPoints(state);
      const isValid =
        rebookingUserid && selectedServices && selectedClinic && selectedDate && selectedTime;

      if (!isValid) {
        throw new Error('rebookingUserid, service, clinic, date and time cannot be null');
      }
      const { filteredServices } = getFilteredServices(selectedServices);
      const totalPrice = getTotalPrice(state)(filteredServices);
      const createBookingPayload = {
        userId: rebookingUserid,
        services: filteredServices,
        clinic: selectedClinic,
        resource: selectedResource,
        date: selectedDate,
        time: selectedTime,
        useOlioPoints,
        isMemberBookedAsGuest,
        isRebooking: true,
        rebookingPrevBookingId: state.booking.bookingId,
        discount: {
          percentageValue: 0,
          priceValue: totalPrice,
        },
      };

      await api.createRebooking(request)(createBookingPayload);
      dispatch(createBookingSuccess(selectedServices, {}));
      posthog.capture('Rebooking fullført', {
        useOlioPoints,
        isMemberBookedAsGuest: isMemberBookedAsGuest,
      });
      navigate(routes.RebookingSuccess(), {
        state: { selectedServices, selectedClinic, selectedTime },
      });
    } catch (err: any) {
      const error = err as Error;
      dispatch(createBookingError(new Error(error.message)));
      dispatch(setIsBookingLoading(false));
      ErrorTracker.error(new Error('FAILED_TO_CREATE_BOOKING'), {
        data: JSON.stringify({ error }),
      });
    }
  };
};

const getResourceForBooking = ({
  isDiscount,
  selectedSlot,
  selectedResourceId,
}: {
  isDiscount: boolean;
  selectedSlot: Slot;
  selectedResourceId: string | null;
}): string[] => {
  if (!isDiscount) return selectedResourceId ? [selectedResourceId] : [];
  const filteredTimeInfos = selectedSlot.timeInfo.filter((ti) =>
    ti?.primaryResources.some((r) => selectedSlot.discountedResourceIds.includes(r))
  );
  const discountedResourceIds = sampleRandom(filteredTimeInfos).primaryResources;
  return discountedResourceIds;
};

export const createBooking = (
  params: { isMemberBookedAsGuest: boolean } = { isMemberBookedAsGuest: false }
) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request, config, routes }: ThunkArguments
  ) => {
    dispatch(createBookingRequest());
    dispatch(setIsBookingLoading(true));
    const state = getState();
    const user = getCurrentUser(state);
    posthog.capture('Booking påbegynt');
    try {
      const token = await dispatch(getBookingToken());
      const selectedServices = getSelectedServices(state);
      const selectedClinic = getSelectedClinic(state);
      const selectedResource = getSelectedResource(state);
      const selectedDate = getSelectedDate(state);
      const selectedTime = getSelectedTime(state);
      const useOlioPoints = getUseOlioPoints(state);
      const isMember = user && user.role === Role.Member;
      const guestInfo = getGuestInfo(state);
      const userData = isMember ? user : guestInfo;
      const promocode = getPromocode(state);
      const isValid = token && selectedServices && selectedClinic && selectedDate && selectedTime;

      if (!isValid) {
        throw new Error('Token, service, clinic, date and time cannot be null');
      }
      const trackData = {
        userData,
        isMember,
        selectedDate,
        selectedTime,
        clinicId: selectedClinic.id,
      };

      let services = selectedServices;
      const { filteredServices } = getFilteredServices(selectedServices);
      services = filteredServices;
      const totalPrice = getTotalPrice(state)(services);
      const selectedSlot = getSelectedSlot(state);
      const discountPercentage = selectedSlot.discountValue ?? 0;
      const payloadResource = getResourceForBooking({
        isDiscount: discountPercentage > 0,
        selectedSlot,
        selectedResourceId: selectedResource?.id,
      });
      const createBookingPayload = {
        token,
        services,
        clinic: selectedClinic,
        resourceIds: payloadResource,
        date: selectedDate,
        time: selectedTime,
        useOlioPoints,
        promocode,
        userData,
        isMemberBookedAsGuest: params.isMemberBookedAsGuest,
        discount: {
          percentageValue: discountPercentage,
          priceValue: totalPrice,
        },
      };

      await api.createBooking(request)(createBookingPayload);
      dispatch(createBookingSuccess(selectedServices, trackData));

      const containsBestseller = services.some((service) => service.showBestsellerBadge);
      const containsNews = services.some((service) => service.showNewsBadge);

      posthog.capture('Booking fullført', {
        useOlioPoints,
        isMemberBookedAsGuest: params.isMemberBookedAsGuest,
        usePromoCode: !!promocode,
        numSelectedServices: services.length,
        totalPrice: totalPrice,
        discountValue: selectedSlot?.discountValue ?? 0,
        containsBestseller,
        containsNews,
      });
      navigate(routes.BookingSuccess(), {
        state: { selectedServices, selectedClinic, selectedTime },
      });
    } catch (err: any) {
      const error = err as Error;
      dispatch(createBookingError(new Error(error.message)));
      dispatch(setIsBookingLoading(false));
      const errorData = JSON.stringify({ user, error });
      ErrorTracker.error(new Error('FAILED_TO_CREATE_BOOKING'), { data: errorData });
    }
  };
};

export const updateBooking = () => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request, routes }: ThunkArguments
  ) => {
    dispatch(updateBookingRequest());
    dispatch(setIsBookingLoading(true));
    const state = getState();
    const user = getCurrentUser(state);

    try {
      const token = await dispatch(getBookingToken());
      const selectedClinic = getSelectedClinic(state);
      const selectedResource = getSelectedResource(state);
      const selectedDate = getSelectedDate(state);
      const selectedTime = getSelectedTime(state);
      const useOlioPoints = getUseOlioPoints(state);
      const bookingId = state.booking.bookingId;
      const selectedServices = getSelectedServices(state);
      let services = selectedServices;

      const { filteredServices } = getFilteredServices(selectedServices);
      services = filteredServices;

      const isValid =
        token && bookingId && services && selectedClinic && selectDate && selectedTime;

      if (!isValid) {
        throw new Error('Token, service, clinic, date and time cannot be null');
      }

      const totalPrice = getTotalPrice(state)(services);
      const selectedSlot = getSelectedSlot(state);
      const discountPercentage = selectedSlot.discountValue ?? 0;
      const payloadResource = getResourceForBooking({
        isDiscount: discountPercentage > 0,
        selectedSlot,
        selectedResourceId: selectedResource?.id,
      });

      await api.updateBooking(request)({
        token,
        bookingId,
        services,
        resourceIds: payloadResource,
        clinic: selectedClinic,
        date: selectedDate,
        time: selectedTime,
        useOlioPoints,
        user,
        discount: {
          percentageValue: discountPercentage,
          priceValue: totalPrice,
        },
      });
      dispatch(updateBookingSuccess());
      dispatch(fetchCurrentUser());
      dispatch(resetBookingForm());
      navigate(routes.BookingSuccess(), {
        state: { selectedServices: services, selectedClinic, selectedTime },
      });
      dispatch(setIsBookingLoading(false));
    } catch (err) {
      const error = err as Error;
      dispatch(updateBookingError(error));
      dispatch(setIsBookingLoading(false));
      const errorData = JSON.stringify({ user, error });
      ErrorTracker.error(new Error('FAILED_TO_UPDATE_BOOKING'), { data: errorData });
    }
  };
};

export const deleteBooking = (clinicId: string, bookingId: string): any => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    const state = getState();
    const user = getCurrentUser(state);
    try {
      const token = getToken(state);
      if (token == null) {
        throw new Error('token is not set');
      }
      const booking = getBookingById(state)(bookingId);
      const selectedServices = getServicesByIds(state)(booking.serviceIds);
      const selectedResource = getResourceById(state)(booking.resourceId);
      const selectedClinic = getClinicById(state)(booking.clinicId);

      dispatch(cancelBookingRequest());
      const response = await api.deleteBooking(request)(token, bookingId, clinicId, user, {
        user,
        services: selectedServices,
        clinicName: selectedClinic.name,
        date: formatToAPIDate(booking.time),
        time: formatToTime(booking.time),
        resourceName: selectedResource?.name,
      });
      const mappedUser = mapToUser(response);
      dispatch(cancelBookingSuccess(bookingId));
      dispatch(resetBookingForm());
      dispatch(fetchCurrentUserSuccess(mappedUser));
      navigate(Routes.UserPage());
      return mappedUser;
    } catch (err) {
      dispatch(cancelBookingError(err));
      const errorData = JSON.stringify({ user, err });
      ErrorTracker.error(new Error('FAILED_TO_DELETE_BOOKING'), { data: errorData });
    }
  };
};
