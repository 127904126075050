import { posthog } from 'posthog-js';
import CallToActionButton from '../../../common/components/callToAction/CallToActionButton';
import { BodyText } from '../../../common/components/texts';
import Routes from '../../app/Routes';
import { User } from '../../user/forces/types';
import styles from './BookingSuccess.module.css';

type Props = {
  user: User | undefined;
};

const BookingSuccessMemberGuestSection = ({ user }: Props) => {
  const isMember = user != null;
  const textForGuests =
    'Bli medlem av Olio sin kundeklubb og få tilgang til egne rabatter og gode deals. Bli medlem idag, helt gratis!';

  const onClickGuestButton = () => {
    posthog.capture('Go to register clicked');
    window.location.href = Routes.Register();
  };

  return (
    <div className={styles.guestMemberTextWrapper}>
      {!isMember && (
        <>
          <BodyText className={styles.noMarginBottom}>{textForGuests}</BodyText>
          <div className={styles.buttonGroupWrapper}>
            <CallToActionButton
              styleType="primary"
              onClick={onClickGuestButton}
              className={styles.buttonWrapper}
            >
              Bli medlem
            </CallToActionButton>
          </div>
        </>
      )}
    </div>
  );
};

export default BookingSuccessMemberGuestSection;
