import { useLocation } from '@reach/router';
import queryString from 'query-string';

export const stringifyObjectWithBrackets = (object: { [key: string]: any }): string => {
  return queryString.stringify(object, {
    arrayFormat: 'bracket',
    skipNull: true,
    skipEmptyString: true,
  });
};

export const parseQueryString = (string: string) => {
  return queryString.parse(string, { arrayFormat: 'bracket' });
};

export const getCurrentHostname = () => {
  const location = useLocation();
  const { protocol, hostname, port } = location;
  const portPart = port && `:${port}`;
  return `${protocol}//${hostname}${portPart}`;
};

export const isBookingPage = () => {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return;
  }
  return location.pathname === '/booking/';
};

export const queryStringToArray = (value?: string | string[]) => {
  if (!value) {
    return [];
  }

  return Array.isArray(value) ? [...value] : [value];
};
