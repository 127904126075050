export const removeDuplicatesByProperty = <T>(arr: T[], prop: string): T[] => {
  const seen = new Set();
  const unique: T[] = [];

  arr.forEach((obj) => {
    const objKey = obj[prop];
    if (!seen.has(objKey)) {
      seen.add(objKey);
      unique.push(obj);
    }
  });

  return unique;
};

export const sampleRandom = <T>(arr: T[]): T => {
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
};
