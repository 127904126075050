import { createReducer } from 'deox';
import { Booking } from 'domain/booking/forces/types';
import { cancelBookingSuccess, fetchBookingSuccess } from '../../booking/forces/actions';
import { TabMenuContent } from '../UserPage';
import {
  fetchCurrentUserError,
  fetchCurrentUserRequest,
  fetchCurrentUserSuccess,
  getUserBookingsSuccess,
  loginRequest,
  loginSuccess,
  logoutSuccess,
  resetPasswordResetFields,
  setActiveTabMenuContent,
  setPasswordResetErrorMessage,
  setPasswordResetIsLoading,
  setPasswordResetSuccessMessage,
  setShowLoginButton,
  setUserError,
  setUserToken,
} from './actions';
import { User } from './types';

interface State {
  token: string | null;
  user: User | null;
  productCount: number;
  passwordReset: {
    isLoading: boolean;
    showLoginButton: boolean;
    errorMessage: string | null;
    successMessage: string | null;
  };
  activeTabMenuContent: TabMenuContent | null;
  isLoading: boolean;
  bookings: Booking[]; // TODO: Might not need this type if we fetch both internal and ACO Booking bookings from mongodb
  error: Error | null;
}

const defaultState: State = {
  token: null,
  user: null,
  productCount: 0,
  passwordReset: {
    isLoading: false,
    showLoginButton: false,
    errorMessage: null,
    successMessage: null,
  },
  activeTabMenuContent: null,
  isLoading: false,
  bookings: [],
  error: null,
};

const userReducer = createReducer(defaultState, (handle) => [
  handle(setUserToken, (state, { payload }) => ({
    ...state,
    token: payload.token,
  })),

  handle(logoutSuccess, (state) => ({
    ...defaultState,
  })),

  handle(fetchCurrentUserRequest, (state) => ({
    ...state,
    isLoading: true,
  })),

  handle(fetchCurrentUserSuccess, (state, { payload }) => ({
    ...state,
    isLoading: false,
    user: { ...payload.user },
  })),

  handle(fetchCurrentUserError, (state) => ({
    ...state,
    isLoading: false,
  })),
  handle(loginRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  handle(loginSuccess, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: null,
    user: { ...payload.user },
  })),

  handle(setUserError, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload.error,
  })),

  handle(setPasswordResetErrorMessage, (state, { payload }) => ({
    ...state,
    passwordReset: {
      ...state.passwordReset,
      isLoading: false,
      errorMessage: payload.errorMessage,
    },
  })),

  handle(setPasswordResetSuccessMessage, (state, { payload }) => ({
    ...state,
    passwordReset: {
      ...state.passwordReset,
      isLoading: false,
      errorMessage: null,
      successMessage: payload.message,
    },
  })),

  handle(setPasswordResetIsLoading, (state, { payload }) => ({
    ...state,
    passwordReset: {
      ...state.passwordReset,
      isLoading: payload.isLoading,
    },
  })),

  handle(setShowLoginButton, (state, { payload }) => ({
    ...state,
    passwordReset: {
      ...state.passwordReset,
      showLoginButton: payload.showLoginButton,
    },
  })),
  handle(resetPasswordResetFields, (state) => ({
    ...state,
    passwordReset: {
      ...defaultState.passwordReset,
    },
  })),
  handle(cancelBookingSuccess, (state, { payload }) => ({
    ...state,
    bookings: state.user.bookings.filter((booking) => booking.id != payload.bookingId),
    isLoading: false,
    error: null,
  })),
  handle(fetchBookingSuccess, (state, { payload }) => {
    const bookingExists = state.user.bookings.find((booking) => booking.id === payload.booking.id);
    const bookings = bookingExists
      ? state.user.bookings
      : [...state.user.bookings, payload.booking];
    return {
      ...state,
      bookings,
      isLoading: false,
      error: null,
    };
  }),
  handle(setActiveTabMenuContent, (state, { payload }) => ({
    ...state,
    activeTabMenuContent: payload.activeTabMenuContent,
  })),
  handle(getUserBookingsSuccess, (state, { payload }) => ({
    ...state,
    bookings: payload.bookings,
  })),
]);

export default userReducer;
