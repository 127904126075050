import { useDispatch, useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import { Service, ServiceCategory } from '../../../../root.types';
import { deselectService, selectService } from '../../forces/actions';
import { BookingSectionIDs } from '../../forces/types';
import { getFetchServicesError, getServiceCategories, getServices } from './forces/selectors';
import styles from './ServiceSection.module.css';
import { useMemo } from 'react';
import { getSelectedServices } from '../../forces/selectors';
import ServiceList from './components/ServiceList';

const RebookingServiceSection = () => {
  const serviceCategories = useSelector(getServiceCategories);
  const error = useSelector(getFetchServicesError);
  const selectedServices = useSelector(getSelectedServices);
  console.log('RebookingServiceSection: selectedServices', selectedServices);
  const sortedServiceCategories =
    serviceCategories && serviceCategories.sort((a, b) => (a.sort > b.sort ? 1 : -1));
  const dispatch = useDispatch();

  if (!serviceCategories) return null;

  const onSelectService = (service: Service) => {
    dispatch(selectService(service));
    // TODO: Don't track prepopulated services

    if (service.isChild) return;
    // Check and select required additionalServices
    const additionalServices = service.additionalServices;
    const hasRequiredAdditionalServices =
      additionalServices && additionalServices.some((s) => s.isRequiredAdditionalService);
    if (!hasRequiredAdditionalServices) return;

    const defaultRequiredAdditionalService = additionalServices.find(
      (s) => s.id === service.defaultRequiredAdditionalServiceId
    );
    if (defaultRequiredAdditionalService) {
      onSelectService(defaultRequiredAdditionalService);
    } else {
      const serviceIndex = additionalServices.length > 1 ? 1 : 0;
      onSelectService(additionalServices[serviceIndex]);
    }
  };

  const serviceCategoryToOpen = useMemo(() => {
    const defaultCategoryToOpen = [sortedServiceCategories[0].id];

    if (!selectedServices) return defaultCategoryToOpen;
    return selectedServices.map((service) => service.serviceCategory?.id).filter(Boolean);
  }, [selectedServices]);

  return (
    <Scroll.Element name={BookingSectionIDs.ServiceSelection}>
      <section className={styles.container}>
        {sortedServiceCategories.map((serviceCategory: ServiceCategory, index: number) => {
          if (serviceCategory.hidden) return null;
          const isOpened = !!serviceCategoryToOpen?.includes(serviceCategory.id);
          return (
            <ServiceList
              key={serviceCategory.id}
              serviceCategory={serviceCategory}
              handleSelection={onSelectService}
              handleDeselection={(service) => dispatch(deselectService(service))}
              isOpened={isOpened}
            />
          );
        })}
      </section>
    </Scroll.Element>
  );
};

export default RebookingServiceSection;
