import { createReducer } from 'deox';
import { Service } from '../../../root.types';
import { GuestInfo } from '../../user/forces/types';
import { Clinic } from '../sections/clinicSection/forces/types';
import { Resource } from '../sections/resourceSection/types';
import {
  cancelBookingError,
  cancelBookingRequest,
  changeCalendarRange,
  createBookingError,
  createBookingRequest,
  createBookingSuccess,
  fetchBookingError,
  fetchBookingRequest,
  fetchBookingsError,
  fetchBookingsRequest,
  fetchBookingsSuccess,
  prePopulateExistingBooking,
  prePopulateRebooking,
  rebookingUserPromptIsSubmitted,
  resetBookingDelete,
  resetBookingError,
  resetBookingForm,
  selectDate,
  selectService,
  selectSlot,
  selectTime,
  setBookingContext,
  setBookingId,
  setIsBookingLoading,
  setPromocode,
  setRebookingUser,
  setSelectedClinic,
  setSelectedResource,
  setSelectedServices,
  setShowLoginOption,
  setUseOlioPoints,
  updateBookingError,
  updateBookingRequest,
  updateBookingSuccess,
  updateGuestInfo,
} from './actions';
import { BookingContext } from './types';
import { RebookingUser } from '../hooks/useUserByBookingId';
import { Slot } from '../sections/calendarSection/forces/types';

export interface State {
  selectedServices: Service[] | null;
  selectedClinic: Clinic | null;
  selectedResource: Resource | null;
  selectedDate: string | null;
  selectedTime: string | null;
  selectedSlot: Slot | null;
  bookingId: string | null;
  dateRange: {
    from: string;
    to: string;
  } | null;
  guestInfo: GuestInfo;
  useOlioPoints: boolean;
  promocode: string | null;
  showLoginOption: boolean;
  bookingContext: BookingContext | null;
  isLoading: boolean;
  error: Error | null;
  rebookingUserPromptIsSubmitted: boolean;
  rebookingUser: RebookingUser | null;
}

const defaultState: State = {
  selectedServices: null,
  selectedClinic: null,
  selectedResource: null,
  selectedDate: null,
  selectedTime: null,
  selectedSlot: null,
  bookingId: null,
  dateRange: null,
  guestInfo: {
    name: '',
    phoneNumber: '',
    email: '',
  },
  useOlioPoints: false,
  promocode: null,
  showLoginOption: false,
  bookingContext: null,
  isLoading: false,
  error: null,
  rebookingUserPromptIsSubmitted: false,
  rebookingUser: null,
};

const bookingReducer = createReducer(defaultState, (handle) => [
  handle(selectService, (state, { payload }) => {
    const newSelectedServices = state.selectedServices
      ? [...state.selectedServices, payload.service]
      : [payload.service];
    return {
      ...state,
      selectedServices: newSelectedServices,
    };
  }),
  handle(setSelectedServices, (state, { payload }) => {
    const selectedServicesAfterDeselection = payload.services;
    const lastServiceWasRemoved = selectedServicesAfterDeselection.length === 0;
    return {
      ...state,
      selectedServices: lastServiceWasRemoved ? null : [...selectedServicesAfterDeselection],
    };
  }),
  handle(prePopulateExistingBooking, (state, { payload }) => ({
    ...state,
    selectedServices: payload.services,
    selectedClinic: payload.clinic,
    selectedDate: payload.date,
    selectedTime: payload.time,
    bookingId: payload.bookingId,
  })),
  handle(prePopulateRebooking, (state, { payload }) => ({
    ...state,
    selectedServices: payload.services,
    selectedClinic: payload.clinic,
    bookingId: payload.bookingId,
    selectedResource: payload.resource,
  })),
  handle(selectDate, (state, { payload }) => ({
    ...state,
    selectedTime: null,
    selectedDate: payload.date,
  })),
  handle(updateGuestInfo, (state, { payload }) => ({
    ...state,
    guestInfo: {
      ...state.guestInfo,
      ...payload,
    },
  })),
  handle(selectTime, (state, { payload }) => ({
    ...state,
    selectedTime: payload.time,
  })),
  handle(selectSlot, (state, { payload }) => ({
    ...state,
    selectedSlot: payload.slot,
  })),
  handle(changeCalendarRange, (state, { payload }) => ({
    ...state,
    dateRange: {
      from: payload.from,
      to: payload.to,
    },
  })),
  // Her skal availabledates settes til []
  handle(setSelectedClinic, (state, { payload }) => ({
    ...state,
    selectedClinic: payload.clinic,
  })),
  // Her skal availabledates settes til []
  handle(setSelectedResource, (state, { payload }) => ({
    ...state,
    selectedResource: payload.resource,
  })),
  handle(setUseOlioPoints, (state, { payload }) => ({
    ...state,
    useOlioPoints: payload.useOlioPoints,
  })),
  handle(fetchBookingError, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload.error,
  })),
  handle(setBookingId, (state, { payload }) => ({
    ...state,
    bookingId: payload.id,
  })),
  handle(setPromocode, (state, { payload }) => ({
    ...state,
    promocode: payload.promocode,
  })),
  handle(setShowLoginOption, (state, { payload }) => ({
    ...state,
    isLoading: false,
    showLoginOption: payload.showLoginOption,
  })),
  handle(setIsBookingLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload.isLoading,
  })),
  handle(resetBookingForm, () => ({
    ...defaultState,
  })),
  handle(fetchBookingsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  handle(fetchBookingsSuccess, (state, { payload }) => {
    const bookings = [...payload.bookings];
    return {
      ...state,
      bookings,
      isLoading: false,
      error: null,
    };
  }),
  handle(fetchBookingsError, (state, { payload }) => ({
    ...defaultState,
    isLoading: false,
    error: payload.error,
  })),

  handle(fetchBookingRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  handle(createBookingRequest, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  handle(createBookingSuccess, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  handle(createBookingError, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload.error,
  })),
  handle(resetBookingError, (state) => ({
    ...state,
    error: null,
  })),
  handle(updateBookingRequest, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  handle(updateBookingSuccess, (state) => ({
    ...state,
    error: null,
    isLoading: false,
  })),
  handle(updateBookingError, (state, { payload }) => ({
    ...state,
    error: payload.error,
    isLoading: false,
  })),
  handle(cancelBookingRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  handle(cancelBookingError, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload.error,
  })),
  handle(resetBookingDelete, (state) => ({
    ...state,
    error: null,
  })),
  handle(setBookingContext, (state, { payload }) => ({
    ...state,
    bookingContext: payload.bookingContext,
  })),
  handle(rebookingUserPromptIsSubmitted, (state, { payload }) => ({
    ...state,
    rebookingUserPromptIsSubmitted: payload.isSubmitted,
  })),
  handle(setRebookingUser, (state, { payload }) => ({
    ...state,
    rebookingUser: payload.rebookingUser,
  })),
]);

export default bookingReducer;
