import { navigate } from '@reach/router';
import { createAction } from 'deox';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../root.reducer';
import { ThunkArguments } from '../../../root.types';
import Routes from '../../app/Routes';
import {
  createBooking,
  createBookingError,
  setIsBookingLoading,
  validateAndScrollBookingSections,
} from '../../booking/forces/actions';
import { scrollToBookingSection } from '../../booking/forces/booking.utils';
import { BookingSectionIDs } from '../../booking/forces/types';
import { removeStoredToken, storeToken } from '../../register/forces/actions';
import {
  fetchCurrentUserSuccess,
  loginSuccess,
  logoutSuccess,
  setUserToken,
} from '../../user/forces/actions';
import { mapToCreateGuestResponse, mapToUser } from '../../user/forces/mapper';
import { UserResponse } from '../../user/forces/types';
import api from './api';
import { identifyUser } from '../../../common/analytics/posthog';

export const loginRequest = createAction('AUTH_LOGIN_REQUEST');
export const loginError = createAction('AUTH_LOGIN_ERROR', (resolve) => (error: Error) => {
  return resolve({ error });
});

export const loginUser = async (
  loginApiFunction: () => Promise<UserResponse>,
  shouldStoreTokenInLocalstorage: boolean,
  createBookingAfterLogin?: boolean
) => {
  return async (dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>) => {
    try {
      dispatch(loginRequest());
      const userResponse = await loginApiFunction();
      const user = mapToUser(userResponse);
      identifyUser(userResponse);
      if (shouldStoreTokenInLocalstorage) {
        storeToken(userResponse.token);
      }
      dispatch(setUserToken(userResponse.token));
      dispatch(fetchCurrentUserSuccess(user));
      dispatch(loginSuccess(user));
      if (createBookingAfterLogin) dispatch(createBooking());
    } catch (err) {
      dispatch(loginError(err as Error));
    }
  };
};

export const loginMember = (
  parameters: {
    phoneNumber: string;
    password: string;
  },
  createBookingAfterLogin?: boolean
) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request, routes }: ThunkArguments
  ) => {
    try {
      dispatch(loginRequest());
      await dispatch(
        await loginUser(() => api.loginMember(request)(parameters), true, createBookingAfterLogin)
      );
    } catch (err) {
      dispatch(
        loginError(new Error('Gikk noe feil. Pass på at mobil nr. og passordet er riktige.'))
      );
    }
  };
};

export const loginGuest = (userId: string) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request, routes }: ThunkArguments
  ) => {
    // Pass false to don't store the token in localstorage as we don't want the user to be
    // logged in the next time they return to the application
    await dispatch(await loginUser(() => api.loginGuest(request)({ userId }), false));
    navigate(routes.UserPage());
  };
};

export const createGuest = (name: string, phoneNumber: string, email: string) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    try {
      const response = await api.createGuest(request)({ name, phoneNumber, email });
      return mapToCreateGuestResponse(response);
    } catch (err) {
      dispatch(createBookingError(err));
      dispatch(setIsBookingLoading(false));
    }
  };
};

export const logout = () => {
  return async (dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>) => {
    removeStoredToken();
    dispatch(logoutSuccess());
  };
};
