class YodaError extends Error {
  code: number;

  constructor(message: string, code: number = 400) {
    super(message);
    this.code = code;
  }
}

export const checkStatus = async (response: any) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const json = await response.json();
  const error = new YodaError(json.message || response.statusText, response.code);
  throw error;
};
