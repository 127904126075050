import { RootState } from '../../../root.reducer';
import { Booking } from '../../booking/forces/types';
import { Role } from './types';

export const getErrorUser = (state: RootState) => state.user.error;
export const getCurrentUser = (state: RootState) => state.user.user;
export const getIsLoadingUser = (state: RootState) => state.user.isLoading;
export const isUserGuest = (state: RootState): boolean => {
  const currentUser = getCurrentUser(state);
  return !!currentUser && currentUser.role === Role.Guest;
};
export const getIsUserLoggedInAsMember = (state: RootState) =>
  state.user.user && state.user.user.role === Role.Member;
export const getUserBookings = (state: RootState) => {
  const currentUser = getCurrentUser(state);
  if (currentUser == null) {
    return [];
  }

  // TODO: In order to fetch directly from ACO Booking, change to [...state.bookings]
  return [...currentUser.bookings];
};
export const isMember = (state: RootState) => {
  const currentUser = getCurrentUser(state);
  return currentUser ? currentUser.role === Role.Member : false;
};
export const getBookingById =
  (state: RootState) =>
  (bookingId: string): Booking => {
    const currentUser = getCurrentUser(state);
    if (!currentUser || !bookingId) return null;
    return getUserBookings(state).find((booking) => booking.id === bookingId);
  };

export const getNameFromBooking = (state: RootState) => {
  const currentUser = getCurrentUser(state);
  return currentUser ? currentUser.name : '';
};
export const hasToken = (state: RootState) => getToken(state) != null;
export const getToken = (state: RootState) => state.user.token;
export const getReferralCode = (state: RootState) => state.user.user.referralCode;
export const getReferralName = (state: RootState) => state.user.user.referralCode;
export const getError = (state: RootState) => state.user.error;
export const getPasswordResetErrorMessage = (state: RootState) =>
  state.user.passwordReset.errorMessage;
export const getPasswordResetSuccessMessage = (state: RootState) =>
  state.user.passwordReset.successMessage;
export const getPasswordResetIsLoading = (state: RootState) => state.user.passwordReset.isLoading;
export const getShowLoginButton = (state: RootState) => state.user.passwordReset.showLoginButton;
export const getActiveTabMenuContent = (state: RootState) => state.user.activeTabMenuContent;
