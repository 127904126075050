import { createReducer } from 'deox';
import {
  fetchAllClinicsError,
  fetchAllClinicsRequest,
  fetchAllClinicsSuccess,
  fetchBookableClinicsError,
  fetchBookableClinicsRequest,
  fetchBookableClinicsSuccess,
  fetchOtherBookableClinicsRequest,
  fetchOtherBookableClinicsSuccess,
  resetBookableClinics,
  shouldFetchOtherBookableClinics,
} from './actions';
import { Clinic, IOtherBookableClinics } from './types';

interface State {
  clinics: Clinic[] | null;
  bookableClinics: {
    clinics: Clinic[] | null;
    isLoading: boolean;
  };
  otherBookableClinics: {
    shouldFetch: boolean;
    clinics: IOtherBookableClinics[] | null;
    isLoading: boolean;
  };
  isLoading: boolean;
  error: Error | null;
}

const defaultState: State = {
  clinics: null,
  bookableClinics: {
    clinics: null,
    isLoading: false,
  },
  otherBookableClinics: {
    shouldFetch: false,
    clinics: null,
    isLoading: false,
  },
  isLoading: false,
  error: null,
};

const clinicReducer = createReducer(defaultState, (handle) => [
  handle(fetchAllClinicsRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  handle(fetchAllClinicsSuccess, (state, { payload }) => ({
    ...state,
    clinics: payload.clinics,
    isLoading: false,
  })),
  handle(fetchAllClinicsError, (state, { payload }) => ({
    ...state,
    error: payload.error,
    isLoading: false,
  })),
  handle(shouldFetchOtherBookableClinics, (state) => ({
    ...state,
    otherBookableClinics: {
      shouldFetch: true,
      clinics: null,
      isLoading: false,
    },
  })),
  handle(fetchBookableClinicsRequest, (state) => ({
    ...state,
    bookableClinics: {
      clinics: null,
      isLoading: true,
    },
  })),
  handle(fetchBookableClinicsSuccess, (state, { payload }) => ({
    ...state,
    bookableClinics: {
      clinics: payload.clinics,
      isLoading: false,
    },
  })),
  handle(fetchBookableClinicsError, (state, { payload }) => ({
    ...state,
    error: payload.error,
    bookableClinics: {
      ...state,
      isLoading: false,
    },
  })),
  handle(fetchOtherBookableClinicsRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  handle(fetchOtherBookableClinicsSuccess, (state, { payload }) => ({
    ...state,
    otherBookableClinics: {
      ...state.otherBookableClinics,
      clinics: payload.otherBookableClinics,
      isLoading: false,
    },
    isLoading: false,
  })),
  handle(resetBookableClinics, (state) => ({
    ...state,
    isLoading: false,
    bookableClinics: {
      clinics: null,
      isLoading: false,
    },
  })),
]);

export default clinicReducer;
