import React from 'react';
import styles from './Modal.module.css';
import classNames from 'classnames';
import useKeyPress from '../../hooks/useKeyPress';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
};

const Modal = ({ children, isOpen, handleClose }: Props) => {
  useKeyPress({
    targetKeyCode: 27,
    targetKey: 'Escape',
    action: handleClose,
  });

  return (
    <div>
      <div
        onClick={handleClose}
        className={classNames(styles.overlay, { [styles.overlayHidden]: !isOpen })}
      />
      <div className={classNames(styles.root, { [styles.rootHidden]: !isOpen })}>
        <button className={styles.exitButton} onClick={handleClose} />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
