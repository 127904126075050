import { createActionCreator } from 'deox';
import { Dispatch } from 'redux';
import { RootState } from '../../../../../root.reducer';
import { ThunkArguments } from '../../../../../root.types';
import {
  getBookingId,
  getDateRange,
  getSelectedClinic,
  getSelectedResource,
  getSelectedServiceIds,
  getSelectedServices,
} from '../../../forces/selectors';
import {
  fetchOtherBookableClinicsRequest,
  fetchOtherBookableClinicsSuccess,
  shouldFetchOtherBookableClinics,
} from '../../clinicSection/forces/actions';
import { getClinics } from '../../clinicSection/forces/selectors';
import { IOtherBookableClinics } from '../../clinicSection/forces/types';
import api from './api';
import { AvailableDate } from './types';
import { hasBookableDates } from './utils';

export const fetchAvailabilityDateRequest = createActionCreator(
  'CLINIC_FETCH_AVAILABILITY_REQUEST',
  (resolve) => (isAdditional?: boolean) => {
    return resolve({ isAdditional });
  }
);

export const fetchAvailableDateSuccess = createActionCreator(
  'CLINIC_FETCH_AVAILABILITY_SUCCESS',
  (resolve) =>
    (
      availableDatesDirectResponse: AvailableDate[],
      options: { appendData: boolean } = { appendData: false }
    ) => {
      return resolve({ availableDatesDirectResponse, options });
    }
);

export const fetchAvailabilityError = createActionCreator(
  'CLINIC_FETCH_AVAILABILITY_ERROR',
  (resolve) => (error: Error) => {
    return resolve({ error });
  }
);

export const fetchAvailability = (isAdditional?: boolean) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
    { request, config }: ThunkArguments
  ) => {
    const state = getState();
    const selectedServices = getSelectedServices(state);
    const selectedServiceIds = getSelectedServiceIds(state);
    const selectedClinic = getSelectedClinic(state);
    const selectedResource = getSelectedResource(state);
    const selectedResourceId = selectedResource ? selectedResource.id : undefined;
    const dateRage = getDateRange(state);
    const bookingId = getBookingId(state);
    const hasSelectedServicesAndClinic = selectedServices && selectedClinic;
    if (!hasSelectedServicesAndClinic) {
      return;
    }

    dispatch(fetchAvailabilityDateRequest(isAdditional));
    try {
      const response = await api.fetchAvailability(request)({
        serviceIds: selectedServiceIds,
        clinicId: selectedClinic.id,
        from: dateRage ? dateRage.from : undefined,
        to: dateRage ? dateRage.to : undefined,
        bookingId,
        resourceId: selectedResourceId,
      });
      if (!hasBookableDates(response)) {
        dispatch(shouldFetchOtherBookableClinics());
      }
      dispatch(fetchAvailableDateSuccess(response, { appendData: isAdditional || false }));
    } catch (err: any) {
      // TODO: Return empty slots with isAvailable: false to not show the error-message
      dispatch(fetchAvailabilityError(err));
    }
  };
};

export const getAvailabilityForClinics = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
    { request, config }: ThunkArguments
  ) => {
    const state = getState();
    const allClinics = getClinics(state);
    const selectedServices = getSelectedServices(state);
    const selectedServiceIds = getSelectedServiceIds(state);
    const selectedResource = getSelectedResource(state);
    const selectedResourceId = selectedResource ? selectedResource.id : undefined;
    const selectedClinic = getSelectedClinic(state);
    if (!selectedServices || !selectedClinic) return;
    const otherClinicsExceptCurrentlySelected = allClinics.filter(
      (clinic) => clinic.id !== selectedClinic.id
    );
    dispatch(fetchOtherBookableClinicsRequest());
    const otherBookableClinics = await Promise.all(
      otherClinicsExceptCurrentlySelected.map(async (clinic) => {
        const response = await api.fetchAvailability(request)({
          serviceIds: selectedServiceIds,
          clinicId: clinic.id,
          resourceId: selectedResourceId,
        });
        const otherBookableClinic = { availability: response, clinic };
        const hasDatesThatCanBeBooked = hasBookableDates(response);
        if (hasDatesThatCanBeBooked) {
          return otherBookableClinic;
        }
      })
    );

    const bookableClinics = otherBookableClinics.filter((clinic) => !!clinic);
    const hasBookableClinics = bookableClinics && bookableClinics.length > 0;
    dispatch(
      fetchOtherBookableClinicsSuccess(
        hasBookableClinics ? (bookableClinics as IOtherBookableClinics[]) : null
      )
    );
  };
};
