import Scroll from 'react-scroll';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CallToActionButton from '../../../../../../common/components/callToAction/CallToActionButton';
import FormAlert from '../../../../../../common/components/form/FormAlert';
import H4 from '../../../../../../common/components/texts/H4';
import Routes from '../../../../../app/Routes';
import LoginPage from '../../../../../auth/LoginPage';
import { getAuthError } from '../../../../../auth/forces/selectors';
import { createBooking, updateBookingError } from '../../../../forces/actions';
import {
  getBookingError,
  getIsLoadingBooking,
  getShowLoginOption,
} from '../../../../forces/selectors';
import styles from './SummarySectionNew.module.css';
import { navigate } from '@reach/router';
import { useDispatch } from '../../../../../../common/store/hooks';
import { SmallText } from 'common/components/texts';
import classNames from 'classnames';

type BookSectionProps = {
  handleCreateBooking: () => void;
  isDisabled: boolean;
};
const CreateBookingSection = ({ handleCreateBooking, isDisabled }: BookSectionProps) => {
  const bookingError = useSelector(getBookingError);
  const errorMessage = bookingError
    ? 'Det oppsto en feil. Vennligst prøv å velg et annet tidspunkt eller ta kontakt med oss for å få hjelp til å booke timen!'
    : null;
  const isLoading = useSelector(getIsLoadingBooking);
  const authError = useSelector(getAuthError);
  const [showLoginComponent, setShowLoginComponent] = useState(false);
  const showLoginOption = useSelector(getShowLoginOption);
  const dispatch = useDispatch();

  if (bookingError || authError) Scroll.animateScroll.scrollToBottom();

  if (errorMessage)
    return (
      <div className={styles.deleteContainer}>
        <FormAlert error={errorMessage} onClose={() => dispatch(updateBookingError(null))} />
        <CallToActionButton
          size="normal"
          styleType="tertiary"
          onClick={() => navigate(Routes.Typeform())}
        >
          Kontakt oss
        </CallToActionButton>
      </div>
    );

  if (showLoginComponent) {
    Scroll.animateScroll.scrollToBottom();
    return (
      <div className={styles.loginPageContainer}>
        <LoginPage createBookingAfterLogin />
        <CallToActionButton onClick={() => dispatch(createBooking())} styleType="light">
          Book heller som gjest
        </CallToActionButton>
      </div>
    );
  }

  if (showLoginOption) {
    Scroll.animateScroll.scrollToBottom();
    return (
      <div className={styles.loginOptionContainer}>
        <H4 className={classNames(styles.text, styles.light)}>
          Du er registrert som medlem hos oss. Ønsker du å logge inn først?
        </H4>
        <div className={styles.loginOptions}>
          <CallToActionButton
            styleType="light"
            onClick={() => dispatch(createBooking({ isMemberBookedAsGuest: true }))}
            className={styles.loginOptionButton}
          >
            Nei. Book som gjest
          </CallToActionButton>
          <CallToActionButton
            styleType="light"
            onClick={() => setShowLoginComponent(true)}
            className={styles.loginOptionButton}
          >
            Ja. Logg inn
          </CallToActionButton>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.bookSectionContainer}>
      <SmallText className={styles.infoContainer}>
        Ved å booke godtar du Olio sine{' '}
        <a
          className={styles.underline}
          href="https://www.olio.no/personvern-og-vilkar"
          target="_blank"
        >
          vilkår for avbestilling og bruk
        </a>
      </SmallText>
      <button className={styles.bookButton} onClick={handleCreateBooking} disabled={isDisabled}>
        {isLoading ? 'Booker timen din...' : 'Bestill'}
      </button>
    </div>
  );
};

export default CreateBookingSection;
