import { RouteComponentProps } from '@reach/router';
import { Field, FieldProps, Form, FormikBag, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CallToActionButton from '../../../common/components/callToAction/CallToActionButton';
import FormAlert from '../../../common/components/form/FormAlert';
import FormInput from '../../../common/components/form/FormInput';
import { RootState } from '../../../root.reducer';
import { changeUserPassword } from '../forces/actions';
import { getCurrentUser } from '../forces/selectors';
import styles from './ChangePassword.module.css';

interface FormValues {
  currentPassword?: string;
  newPassword?: string;
  changeUserPassword?: (values: any) => void;
}
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & FormValues & React.HTMLAttributes<HTMLElement> & RouteComponentProps;
type PropsWithFormik = Props & React.HTMLAttributes<HTMLElement> & FormikProps<FormValues>;

const ChangePassword: React.FunctionComponent<PropsWithFormik> = ({
  className,
  children,
  currentUser,
  ...props
}) => {
  if (currentUser == null) return null;

  return (
    <div>
      <Form>
        <Field
          name="currentPassword"
          render={({ field }: FieldProps<FormValues>) => (
            <FormInput
              {...field}
              styleType="secondary"
              type="password"
              name="currentPassword"
              required
              placeholder="Nåværende passord"
            />
          )}
        />
        <Field
          name="newPassword"
          render={({ field }: FieldProps<FormValues>) => (
            <FormInput
              {...field}
              styleType="secondary"
              type="password"
              name="newPassword"
              required
              placeholder="Nytt passord"
            />
          )}
        />
        <CallToActionButton
          className={styles.loginButton}
          styleType="tertiary"
          disabled={
            props.isSubmitting ||
            props.values.currentPassword.length == 0 ||
            props.values.newPassword.length === 0
          }
          type="submit"
        >
          Lagre
        </CallToActionButton>
        {<FormAlert error={props.error} success={props.status} />}
      </Form>
    </div>
  );
};

const formOptions = {
  mapPropsToValues: (props: Props) => ({
    currentPassword: '',
    newPassword: '',
    changeUserPassword,
  }),
  handleSubmit: async (
    values: { currentPassword: string; newPassword: string },
    formikBag: FormikBag<Props, FormValues>
  ) => {
    if (values.currentPassword === values.newPassword) {
      formikBag.resetForm();
      formikBag.setError('Nåværende passord og nytt passord kan ikke være samme');
      formikBag.setSubmitting(false);
      return;
    }

    try {
      await formikBag.props.changeUserPassword(values);
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      formikBag.setStatus('Passordet ditt har blitt oppdatert.');
    } catch (err) {
      formikBag.resetForm();
      formikBag.setError(err.message || 'Noe gikk galt');
      formikBag.setSubmitting(false);
    }
  },
};
const FormikLogin = withFormik<Props, FormValues>(formOptions)(ChangePassword);

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      changeUserPassword,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  currentUser: getCurrentUser(state),
});

export default connect<StateProps, {}, FormValues, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(FormikLogin);
