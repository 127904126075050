import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';
import FormAlert from '../../common/components/form/FormAlert';
import { Container } from '../../common/components/grid/Grid';
import Layout from '../../common/components/layout/Layout';
import LoadingIndicator from '../../common/components/loadingIndicator/LoadingIndicator';
import { assertUnreachable } from '../../common/utils/ts.utils';
import { RegistrationStep } from '../user/forces/types';
import RegistrationGeneralForm from './components/RegistrationGeneralForm';
import RegistrationSummary from './components/RegistrationSummary';
import { getCurrentStep, getIsLoadingRegister, getRegisterError } from './forces/selectors';
import styles from './RegisterPage.module.css';

type Props = RouteComponentProps;

const RegisterPage = (props: Props) => {
  const isLoading = useSelector(getIsLoadingRegister);
  const error = useSelector(getRegisterError);
  const errorMessage = error ? error.message || 'Det gikk noe galt her.' : null;
  const currentStep = useSelector(getCurrentStep);

  const renderCurrentStep = () => {
    switch (currentStep) {
      case RegistrationStep.GeneralForm:
        return <RegistrationGeneralForm />;
      case RegistrationStep.Summary:
        return <RegistrationSummary />;
      default:
        return assertUnreachable(currentStep);
    }
  };

  const showErrorMessage = error && !isLoading;

  return (
    <Layout>
      <Container>
        <div className={styles.container}>
          {renderCurrentStep()}
          {isLoading && <LoadingIndicator className={styles.loadingIndicator} />}
          {showErrorMessage && <FormAlert error={errorMessage} />}
        </div>
      </Container>
    </Layout>
  );
};

export default RegisterPage;
