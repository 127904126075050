import { RootState } from '../../../../../root.reducer';
import { AvailableDate } from './types';

export const getLoading = (state: RootState) => state.calendar.isLoading;
export const getError = (state: RootState) => state.calendar.error;
export const getAvailableDatesDirect = (state: RootState): AvailableDate[] => {
  return state.calendar.availableDates;
};
export const isAdditionalAvailableDatesLoading = (state: RootState) =>
  state.calendar.additionalAvailableDates.isLoading;
