import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CallToActionLink from '../../../common/components/callToAction/CallToActionLink';
import Layout from '../../../common/components/layout/Layout';
import SectionSubtitle from '../../../common/components/section/SectionSubtitle';
import { appConfig } from '../../../config';
import { logout } from '../forces/actions';
import styles from './Logout.module.css';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Layout>
      <div className={styles.container}>
        <SectionSubtitle className={styles.text}>Du har nå logget ut. Vi sees!</SectionSubtitle>
        <CallToActionLink to={appConfig.websiteDomain} styleType="primary">
          Hjem
        </CallToActionLink>
      </div>
    </Layout>
  );
};

export default Logout;
