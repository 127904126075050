import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import LoadingIndicator from '../../../../common/components/loadingIndicator/LoadingIndicator';
import { BodyText, H2 } from '../../../../common/components/texts';
import { setSelectedClinic } from '../../forces/actions';
import { getSelectedClinic, getSelectedServices } from '../../forces/selectors';
import { BookingSectionIDs } from '../../forces/types';
import { fetchAvailability } from '../calendarSection/forces/actions';
import { fetchResources } from '../resourceSection/forces/actions';
import styles from './ClinicSection.module.css';
import ClinicButtons from './components/ClinicButtons';
import { fetchBookableClinics } from './forces/actions';
import { getBookableClinics, getIsLoadingBookableClinics } from './forces/selectors';
import { Clinic } from './forces/types';
import { trackBookingStep } from '../../../../common/analytics/posthog';

const ClinicSection = () => {
  const isLoading = useSelector(getIsLoadingBookableClinics);
  const bookableClinics = useSelector(getBookableClinics);
  const hasBookableClinic = bookableClinics && bookableClinics.length > 0;
  const selectedServices = useSelector(getSelectedServices);
  const selectedClinic = useSelector(getSelectedClinic);
  const showClinicButtons = selectedServices && hasBookableClinic;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedServices) {
      dispatch(fetchBookableClinics());
    }
  }, [dispatch, selectedServices]);

  const handleSelectClinic = (clinic: Clinic) => {
    const isSelected = selectedClinic && selectedClinic.id === clinic.id;
    if (isSelected) return;
    dispatch(setSelectedClinic(clinic));
    dispatch(fetchResources(clinic.id, selectedServices));
    dispatch(fetchAvailability());
    trackBookingStep('Velg lokasjon', { locationId: clinic.id, locationName: clinic.name });
  };
  const renderSection = () => {
    if (!selectedServices)
      return (
        <BodyText className={styles.title}>
          Velg behandling først for å kunne velge lokasjon
        </BodyText>
      );
    if (isLoading) return <LoadingIndicator loadingText="Finner lokasjoner for dine valg" />;
    if (showClinicButtons) {
      return (
        <div className={styles.clinicButtonContainer}>
          <ClinicButtons
            clinics={bookableClinics}
            handleSelectClinic={handleSelectClinic}
            selectedClinicId={selectedClinic ? selectedClinic.id : null}
          />
        </div>
      );
    }
    return (
      <BodyText className={styles.title}>
        Vi har ingen lokasjon som tilbyr valgte behandling(er)
      </BodyText>
    );
  };

  return (
    <Scroll.Element name={BookingSectionIDs.ClinicSection}>
      <section className={styles.container}>
        <H2 className={styles.title}>Lokasjon</H2>
        <div className={styles.divider} />
        {renderSection()}
      </section>
    </Scroll.Element>
  );
};

export default ClinicSection;
