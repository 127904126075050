import React from 'react';
import { useDispatch } from 'react-redux';
import AnimateHeight from '../../../../../common/components/animateHeight/AnimateHeight';
import { useSelector } from '../../../../../common/store/hooks';
import { Service } from '../../../../../root.types';
import { setSelectedClinic, setSelectedResource, setUseOlioPoints } from '../../../forces/actions';
import { isServiceSelected } from '../../../forces/selectors';
import { resetResources } from '../../resourceSection/forces/actions';
import CheckboxLine from './CheckboxLine';
import ChildServiceItems from './ChildServiceItems';
import styles from './ServiceItem.module.css';
import RequiredChildServiceItems from './RequiredChildServiceItems';
import ServicePrice from './ServicePrice';
import ServiceBadge from './ServiceBadge';

type Props = {
  service: Service;
  handleSelection: (service: Service) => void;
  handleDeselection: (service: Service) => void;
  isMenuListOpened?: boolean;
} & React.HTMLAttributes<HTMLElement>;

const ServiceItem = ({ service, handleSelection, handleDeselection, isMenuListOpened }: Props) => {
  const isSelectedGetter = useSelector(isServiceSelected);
  const additionalServices = service.additionalServices;
  const hasAdditionalServices = additionalServices && additionalServices.length > 0;
  const hasRequiredAdditionalServices =
    hasAdditionalServices && additionalServices.some((s) => s.isRequiredAdditionalService);
  const isSelected = isSelectedGetter(service);
  const dispatch = useDispatch();

  const resetSections = () => {
    dispatch(setSelectedClinic(null));
    dispatch(setSelectedResource(null));
    dispatch(resetResources());
    dispatch(setUseOlioPoints(false));
  };

  const renderAdditionalServices = () => {
    if (!hasAdditionalServices) return <></>;

    if (hasRequiredAdditionalServices) {
      return (
        <div className={styles.additionalServices}>
          <RequiredChildServiceItems
            childServices={service.additionalServices}
            parentService={service}
            handleSelection={handleSelection}
            handleDeselection={handleDeselection}
            resetSections={resetSections}
          />
        </div>
      );
    }

    return (
      <div className={styles.additionalServices}>
        <ChildServiceItems
          childServices={service.additionalServices}
          handleSelection={handleSelection}
          handleDeselection={handleDeselection}
          resetSections={resetSections}
        />
      </div>
    );
  };

  const handleServiceSelection = (service: Service) => {
    handleSelection(service);
    resetSections();
  };

  return (
    <div
      className={styles.serviceRow}
      onClick={() => {
        if (isSelected) {
          handleDeselection(service);
        } else {
          handleServiceSelection(service);
        }
      }}
    >
      <ServiceBadge service={service} isMenuListOpened={isMenuListOpened} />
      <div className={styles.serviceItemWrapper}>
        <div className={styles.serviceItem}>
          <CheckboxLine
            content={<p className={styles.serviceTitle}>{service.displayTitle}</p>}
            isSelected={isSelected}
            onClick={() => {}}
          />
          <ServicePrice service={service} />
        </div>
        {service.description && <p className={styles.serviceDescription}>{service.description}</p>}
      </div>

      <AnimateHeight height={isSelected ? 'auto' : 0}>{renderAdditionalServices()}</AnimateHeight>
    </div>
  );
};

export default ServiceItem;
