import { createAction } from 'deox';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../root.reducer';
import { ThunkArguments } from '../../../root.types';
import { fetchAllClinics } from '../../booking/sections/clinicSection/forces/actions';
import { fetchServices } from '../../booking/sections/serviceSection/forces/actions';
import { fetchCurrentUserIfNeeded, setStoredToken } from '../../user/forces/actions';

export const appSetupRequest = createAction('APP_SETUP_REQUEST');
export const appSetupSuccess = createAction('APP_SETUP_SUCCESS');

export const appSetup = () => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    dispatch(appSetupRequest());

    // Load the stored token on initial page load
    dispatch(setStoredToken());

    // Fetch services
    await dispatch(fetchServices());

    // Fetch all clinics
    await dispatch(fetchAllClinics());

    // Fetch the current user on initial page load
    await dispatch(fetchCurrentUserIfNeeded());

    dispatch(appSetupSuccess());
  };
};
