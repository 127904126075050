import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Service } from 'root.types';
import styles from './ServiceBadge.module.css';

const ServiceBadge = ({
  service,
  isMenuListOpened,
}: {
  service: Service;
  isMenuListOpened?: boolean;
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isMenuListOpened) {
      setIsMounted(true);
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [isMenuListOpened]);

  if (!isMounted) return null;
  if (service.showBestsellerBadge) {
    return (
      <div
        className={classNames(styles.badge, styles.mostPopularBadge, {
          [styles.showBadge]: isVisible,
        })}
      >
        <span>Bestselger</span>
      </div>
    );
  }

  if (service.showNewsBadge) {
    return (
      <div
        className={classNames(styles.badge, styles.newsBadge, {
          [styles.showBadge]: isVisible,
        })}
      >
        <span>Nyhet</span>
      </div>
    );
  }
};

export default ServiceBadge;
