import { createReducer } from 'deox';
import { appSetupRequest, appSetupSuccess } from './actions';

interface State {
  isLoading: boolean;
}

const defaultState: State = {
  isLoading: false,
};

const appReducer = createReducer(defaultState, (handle) => [
  handle(appSetupRequest, (state) => ({
    ...defaultState,
    isLoading: true,
  })),
  handle(appSetupSuccess, (state) => ({
    ...defaultState,
    isLoading: false,
  })),
]);

export default appReducer;
