import { createActionCreator } from 'deox';
import { Dispatch } from 'redux';
import { RootState } from '../../../../../root.reducer';
import { ThunkArguments } from '../../../../../root.types';
import { getSelectedServiceIds } from '../../../forces/selectors';
import api from './api';
import { mapResponseToAllClinics } from './mappers';
import { getClinics } from './selectors';
import { Clinic, IOtherBookableClinics } from './types';

export const shouldFetchOtherBookableClinics = createActionCreator(
  'CLINIC_SHOULD_FETCH_OTHER_BOOKABLE_CLINICS'
);

export const fetchOtherBookableClinicsRequest = createActionCreator(
  'CLINIC_GET_OTHER_BOOKABLE_CLINICS_REQUEST'
);

export const fetchOtherBookableClinicsSuccess = createActionCreator(
  'CLINIC_FETCH_OTHER_BOOKABLE_CLINICS_SUCCESS',
  (resolve) => (otherBookableClinics: IOtherBookableClinics[] | null) => {
    return resolve({ otherBookableClinics });
  }
);

export const fetchBookableClinicsRequest = createActionCreator(
  'CLINIC_FETCH_BOOKABLE_CLINICS_REQUEST'
);

export const fetchBookableClinicsSuccess = createActionCreator(
  'CLINIC_FETCH_BOOKABLE_CLINICS_SUCCESS',
  (resolve) => (clinics: Clinic[] | null) => {
    return resolve({ clinics });
  }
);

export const fetchBookableClinicsError = createActionCreator(
  'CLINIC_FETCH_BOOKABLE_CLINICS_ERROR',
  (resolve) => (error: Error) => {
    return resolve({ error });
  }
);

export const fetchBookableClinics = () => {
  return async (dispatch: Dispatch, getState: () => RootState, { request }: ThunkArguments) => {
    dispatch(fetchBookableClinicsRequest());
    try {
      const allClinics = getClinics(getState());
      const selectedServiceIds = getSelectedServiceIds(getState());
      if (!selectedServiceIds) return;
      const response = await api.fetchClinicsThatServeSelectedServices(request)(selectedServiceIds);
      const hasClinicsThatServeSelectedServices = response.length > 0;
      if (hasClinicsThatServeSelectedServices) {
        const clinicsThatServeSelectedServices = allClinics.filter((clinic) =>
          response.includes(clinic.id)
        );
        return dispatch(fetchBookableClinicsSuccess(clinicsThatServeSelectedServices));
      }
      dispatch(fetchBookableClinicsSuccess(null));
    } catch (err) {
      dispatch(fetchBookableClinicsError(err));
    }
  };
};

export const resetBookableClinics = createActionCreator('CLINICS_RESET_BOOKABLE_CLINICS');
export const fetchAllClinicsRequest = createActionCreator('GET_ALL_CLINICS_REQUEST');
export const fetchAllClinicsSuccess = createActionCreator(
  'GET_ALL_CLINICS_SUCCESS',
  (resolve) => (clinics: Clinic[] | null) => {
    return resolve({ clinics });
  }
);

export const fetchAllClinicsError = createActionCreator(
  'GET_ALL_CLINICS_ERROR',
  (resolve) => (error: Error) => {
    return resolve({ error });
  }
);

export const fetchAllClinics = () => {
  return async (dispatch: Dispatch, getState: () => RootState, { request }: ThunkArguments) => {
    dispatch(fetchAllClinicsRequest());
    try {
      const response = await api.fetchAllClinics(request)();
      const allClinics = mapResponseToAllClinics(response);
      dispatch(fetchAllClinicsSuccess(allClinics));
      return allClinics;
    } catch (err) {
      dispatch(fetchAllClinicsError(err));
    }
  };
};
