import classNames from 'classnames';
import { formatToNOK } from 'common/utils/currency.utils';
import { BodyBolderText } from '../../../../../common/components/texts';
import { Service } from '../../../../../root.types';
import { getSortedServicesByPrice } from '../forces/service.utils';
import styles from './ServiceItem.module.css';

interface Props {
  service: Service;
}

const FromPrice = ({ price }: { price: number }) => (
  <p className={classNames(styles.servicePrice, styles.bodyText)}>{`fra ${formatToNOK(
    price
  )},-`}</p>
);

const DiscountPrice = ({
  price,
  discountPrice,
  variant = 'regular',
}: {
  price: number;
  discountPrice?: number;
  variant?: 'regular' | 'from';
}) => {
  return (
    <div className={styles.serviceItem}>
      <div className={styles.discountWrapper}>
        {variant === 'regular' ? (
          <BodyBolderText className={styles.servicePrice}>{formatToNOK(price)},-</BodyBolderText>
        ) : (
          <FromPrice price={price} />
        )}
        <span className={styles.lineThrough} />
      </div>
      <BodyBolderText className={styles.servicePrice}>
        {formatToNOK(discountPrice)},-
      </BodyBolderText>
    </div>
  );
};

const ServicePrice = ({ service }: Props) => {
  const hasDiscountPrice =
    typeof service.discountPrice === 'number' && service.discountPrice < service.price;
  const isAdditionalService = service.parentServiceId !== undefined && !service.isPackageDeal;
  const hasRequiredAdditionalServices = service.additionalServices?.some(
    (s) => s.isRequiredAdditionalService
  );

  if (hasDiscountPrice) {
    return <DiscountPrice price={service.price} discountPrice={service.discountPrice} />;
  }

  const renderPrice = () => {
    // TODO: Special case for Botox-injections. Should be removed/refactored if multiple cases of this happens
    if (service.waitwhileServiceId === 'mB4iFNvdIytmuhH5ICW6') {
      return (
        <BodyBolderText className={styles.servicePrice}>{`fra ${formatToNOK(
          service.price
        )},-`}</BodyBolderText>
      );
    }
    if (hasRequiredAdditionalServices) {
      const cheapestAdditionalService = getSortedServicesByPrice(service.additionalServices)[0];
      const requiredHasDiscount =
        cheapestAdditionalService.discountPrice &&
        cheapestAdditionalService.discountPrice < cheapestAdditionalService.price;

      if (requiredHasDiscount) {
        return (
          <DiscountPrice
            price={cheapestAdditionalService.price}
            discountPrice={cheapestAdditionalService.discountPrice}
            variant="from"
          />
        );
      }

      return <FromPrice price={cheapestAdditionalService.price} />;
    }
    if (isAdditionalService) {
      return (
        <BodyBolderText className={styles.servicePrice}>{`+${formatToNOK(
          service.price
        )},-`}</BodyBolderText>
      );
    }
    return (
      <BodyBolderText className={styles.servicePrice}>
        {formatToNOK(service.price)},-
      </BodyBolderText>
    );
  };

  return renderPrice();
};

export default ServicePrice;
