import * as React from 'react';
import classNames from 'classnames';
import { ErrorMessageProps, ErrorMessage } from 'formik';
import styles from './FormInputError.module.css';

type Props = {} & React.HTMLAttributes<HTMLElement> & ErrorMessageProps;

const FormInputError: React.FunctionComponent<Props> = ({ className, ...props }) => {
  return <ErrorMessage className={classNames(styles.container, className)} {...props} />;
};

export default FormInputError;
