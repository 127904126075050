export const acoBookingToWwClinicIdMap = {
  'b8aa8428-881c-44ae-becb-6336a636c3e8': 'PLIQM9iEt6uWHPP2JVrI', // 'Vika',
  'b57d140d-3f74-49d1-a5b3-902cc798b246': 'ZgHMtlL1luOdCPB10UZI', // 'Majorstuen',
  '05810c03-af9c-4fb4-a69f-0f8ffe527fb5': '3CMnSBq5mO7yAUMsaFrk', // 'Grünerløkka',
  '408ab530-84ac-46d1-acdc-21a6c87f888c': '7eF3zNYAMU6LNSXzwOmA', // 'Ski Storsenter',
  '973422b5-2a9c-4040-8245-5d6122488b97': '7q9wTpkDD0H5Ur5hj2rW', // 'Sagene',
  '97457763-78f4-46bd-8f8b-f2b5bfac3b62': 'rKDqWXqu0b9WGIAPrvUm', // 'Oslo City',
  '55e99990-6731-403b-9063-a739c30a923f': 'DJTSoryWxaHUiggq0WjT', // 'Storo',
  '86ff50aa-8e2a-49ee-b6ae-da1850614801': 'KrGDXVPIqBDwQKKNZolw', // 'Lagunen',
  'aeec617f-b2c1-460b-9092-751a2b731afe': '2iZH42aDANo9SMFlXQaQ', // 'Xhibition',
  '915dd718-ed23-4c5f-9616-969cb7d288d0': 'Rv3ktsEle8AbhWaZKLqf', // 'Sandvika',
  // 'beb016ac-25d8-4601-b26e-267b2c4a8192': 'Rv3ktsEle8AbhWaZKLqf', // 'Sandvika',
  '1354dac8-88e8-4e31-9efa-861ac3d37580': 'Etig1AfEhxnFhBzUUef9', // 'Ensjø',
  '665a3905-970c-4abe-ac8d-7fbf578b732d': 'MpseOmuXjt3C8Ehf4bqV', // 'MajorstuenTest',
  // Lillestrøm is removed
} as const;

export const wwToAcoBookingClinicMap = Object.entries(acoBookingToWwClinicIdMap).reduce(
  (acc, [acoBookingId, wwClinicId]) => {
    acc[wwClinicId] = acoBookingId;
    return acc;
  },
  {} as Record<string, string>
);

export const waitwhileToAcoBookingClinicId = (clinicId: string) => {
  return Object.entries(acoBookingToWwClinicIdMap).find(
    ([_, acoBookingId]) => acoBookingId === clinicId
  )?.[0];
};

export const acoBookingToWwClinicId = (acoBookingId: string) => {
  return acoBookingToWwClinicIdMap[acoBookingId];
};

export const roomResourceIds = [
  '7a76d30c-6391-4fe5-9274-007d3780d2f6',
  '0c4f7b05-386c-43e5-957a-1444d9d33074',
  '510c80f3-23df-498d-99af-92ae2d378245',
  'd2ee249d-8a3a-4809-828d-f74decbcd291',
  '94385129-c470-4425-ae5c-b104824d32fd',
  'eda9885f-8078-438f-a8c6-f2b4bd0c7db3',
  '9cb37c16-198b-4cd6-bd8a-bede1388a876',
  'b3abee6b-f766-4d7b-9591-7dd9f7b0eccb',
  '33d90ec5-ecf6-4841-bd43-d9ca47013dda',
  '2c9507a2-e507-471e-9ad5-0f93bb3341aa',
  'a137df3d-29a1-49a8-8d91-699884f02391',
  'e3c728bd-ccf9-4e14-b78e-822ef1aebbb0',
  'a538606e-5811-41c1-84af-7f2d829f545d',
  'f81e265e-5bda-4d2f-969d-132684957ffe',
  'a2dd0c24-3626-48bc-bb94-e9c19674a9d6',
  '848774d8-cf4d-400b-ad19-df886805ba26',
  '0f01fe44-0f88-4014-abd7-cf772cb54ac0',
  '3ed8b0b2-3c5f-463d-a265-9b05af3e875d',
  'daffcabf-a28a-45b7-b079-1919a59a2d8f',
  '2e525bf6-246f-4b6f-8d38-b1c803bb1b1e',
  '8bf24844-9322-43a4-9a17-758e18cbec44',
  'c680e706-8cda-4aec-98d6-db4e12a2d0df',
  '48343ddb-e5eb-4b16-bb12-57fba5376162',
  'a68c28f4-ef47-45a7-b085-1e6e38f0a661',
  '81626345-23b5-4bdd-871d-6c5bdb589f29',
  '3986b757-e95a-4f43-a93d-3ba103eaa6a4',
  '2ea763ee-e951-419d-aca6-bc653f7c58c9',
  '68bd7b27-5c4b-4377-9a9f-d70f0a5bd82b',
  '8c7a22e7-7cdd-4390-a035-f7c7e72643d6',
  'f6bfc612-6bd0-45b5-b584-457b49a25715',
  '299f8ab3-0c1a-4e6f-b59b-158983a40073',
  '608305f8-72b8-4cf8-8e07-cc82d1038dcb',
  '7fad4adb-98b6-47a6-89b3-f4b7c6efc8fe',
  '551e7fc7-8da3-4c55-bd64-6cf0ab112af4',
  '4efdaad3-34fb-4571-992a-e471f2d98aca',
  '2a848d9a-5a55-4fd7-802f-7cf339029f6e',
  'a0fad76c-ea5c-4a99-b9bf-8d2b34e9055f',
  '60e6fdba-180e-4207-88dd-924a078c8455',
  '343b7355-aae8-4c55-80ab-9c0ba48d319b',
  '18a506af-214b-4b5a-8a83-9bbd8c91b774',
  '25a4b57e-4a78-453b-b19a-ef2ee9c95b43',
  'dedba70c-fe24-4a64-810e-cd25ac8b5444',
  '1457ca55-808e-4f54-8472-6aa509fb7bdd',
  '407d2202-bc29-4820-81f5-83dbdd7c9fa1',
  'fe5eb65e-030a-460b-9438-a3eeafec70a7',
  'd79f9c64-a889-478a-af64-b49ab517f4c9',
  '0b7e0c0d-f200-4b85-9221-04d08a2fcf19',
  '32b1d750-3f18-404a-b35e-f476c17bb5bb',
  '588bd7f1-6f0f-4c31-a031-1e885e503b31',
  'd83680bb-8eda-433d-ab3c-93b2a2c165db',
  '1cba0874-d366-483e-8e16-43fc982c906d',
  '79338359-034a-4b7f-bd0f-a067e3e50153',
  '0f13c72b-f518-4ebf-8e7f-99cfca30dd9b',
  'cf3047f3-2563-479b-b410-e729d40e3df0',
  '886f61bc-07ec-4ce5-b7c7-851b4e9fe9ed',
  'c04ed546-ed56-4cf7-b2f2-6562f1ec8140',
  '4fb49cc7-ae63-48d6-858a-2618c12f7708',
  '80baebc4-801d-4d74-ba5a-7de531922be0',
  '8722dd03-55ec-493a-b859-e219380685a7',
  'eb8edabf-6d15-49c7-872c-2970069cd73c',
  '90e99db9-3be2-44ec-9720-921f4037d361',
  '9827498d-f24e-4c00-913a-104c19e2b39f',
  '97b52b4c-764d-4fbe-aed4-9fac96d87d9b',
  '8040ad8f-0f25-469e-b063-6a0eebfce772',
  '86a354b8-ac10-4cd2-b087-687961825f3f',
  'c8e0db48-447a-4a3b-b28f-1faecbfd69dd',
  '4ae45129-bae7-4f27-b1c1-00ff87ba8bce',
  'e258c8bc-b1fd-4336-9be3-b687b9070b42',
  'cc8a5e02-77ab-40b2-899b-0172c395631d',
  '588544d9-098c-4fc2-8082-79f384e3f9f3',
  '379c8d92-8a7d-43b9-9b3a-876bc17ff16a',
  '3c24a68b-b01a-4086-95cf-bab95e08d0d5',
  '769898fd-9d76-43f8-9c01-70402468cfcf',
  'ee2a0dc3-15dc-481c-a32f-8b03659679d2',
  '0355f5d5-31ae-4d0f-95d0-03c3a02869b5',
  'e0d45738-7cde-41ca-832e-5dcec7746080',
  'f57f0a71-55be-4a25-bf9d-cd11e89883c9',
  '612c20aa-7430-4de2-afe3-35d3d0e0cd72',
  'd2421254-052c-47a1-962c-d87941e05b90',
  'c43463aa-b1bf-450a-8061-0b407d8cf411',
  'b84e4e51-c8ca-4f4c-8dc8-d3ef3ecf4401',
  'b96a21eb-c72f-4c9b-b90a-0c4b343ba98e',
  '7381e5b1-a053-4e71-b141-61495eb97b6c',
  '411dad2a-ef9b-460e-a0b0-e9259dc5b91f',
  '82708881-bc46-41f5-b1bc-6989c0564578',
  '9db53992-606f-4a4d-8a08-63e032b61380',
  '9f7498a3-2b9c-4314-9230-682b07268c07',
  'afc7311e-2760-44e8-8e85-7e92fbde0168',
  '0ce2ec84-16f3-4226-8eae-8661ec08840f',
  '8308913e-a88c-4c32-a070-f5891cceb966',
  '0daad310-3a57-49c5-b302-61c217f401d0',
  'e16c7c4c-e9e7-4ca0-a681-75d7c667ead1',
  'dca07b33-f2d5-4e06-9a81-2a2e94cfef56',
  '5da938a9-2ece-4646-8208-f56783ca4d06',
  'c3f364d0-d077-413d-b475-efa3d878efaa',
  '331b0905-6827-4855-ba6c-b0ade7b527aa',
  'b9a3002d-82b9-47ae-93cc-bcb12aca9458',
  '59402496-ad43-4d5d-ae95-aea79590622b',
  'b48111a1-e35a-4028-b22f-fbf1bc90b3f3',
  '259fbab1-f07c-4dd9-ae13-b76fe33765dc',
  '9fa0f933-20f0-486f-9bc4-02f24cb80e5e',
  '74b870fc-9c98-4695-8b8d-47c7b7e0ecda',
  '5eb1b68e-c613-48b5-b591-2b908bbd03cb',
  'beb266b3-58f9-489b-a4b0-e3c9bfe7ab30',
  '68b00d50-4abb-49ca-be89-e0a0d738444a',
  '40d8dde7-0ea0-48e2-bcc8-42794234f229',
  '876b6191-98d5-48e0-a107-a7ec005ff36f',
  'a42847f0-eea1-4e2f-876f-b2d8cbe2d56c',
  '050896ac-9cb0-4867-b0d2-7a851007ce7c',
  '09ea6898-bfae-4a93-8ee5-25f136e444da',
  '9e9b7695-a178-47d0-8083-027982f038f8',
  'b12d19a0-8fc8-4ee6-99ed-6ea986ad6766',
  'e76b0df7-1d5b-4cfa-ab85-3e1782becc23',
  'df64d1da-8a55-4ae4-a1f9-c791b42b9714',
  '4b750177-1740-4286-a125-6cd51647c3d9',
  '3a44b7c8-3274-4d95-8880-4701fbe529eb',
  '1b491e3a-9495-43ee-8141-901457099245',
  'bbd7b99c-9e8c-436a-a27d-64da03582f2a',
  '374b9a25-972f-4681-839f-eb163b5263bc',
  '4f0c0f29-fab9-442e-a3cf-94a7c386f4a4',
  '3c8c7019-7844-4da7-a27b-27feb0a5dc5c',
];
