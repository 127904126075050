import * as React from 'react';
import Link from '../link/Link';
import CallToAction, { CallToActionProps } from './CallToAction';

type Props = {
  to: string;
} & CallToActionProps &
  React.HTMLAttributes<HTMLElement>;

const CallToActionLink: React.FunctionComponent<Props> = ({ ...props }) => {
  return <CallToAction element={Link} {...props} />;
};

export default CallToActionLink;
