import { createAction } from 'deox';
import { Booking } from 'domain/booking/forces/types';
import { ReactStripeElements } from 'react-stripe-elements';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../root.reducer';
import { ThunkArguments } from '../../../root.types';
import { getStoredToken } from '../../register/forces/actions';
import { TabMenuContent } from '../UserPage';
import api from './api';
import { mapToUser } from './mapper';
import { getCurrentUser, getToken, hasToken } from './selectors';
import { User } from './types';

export const setStoredToken = () => {
  return (dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>) => {
    const storedToken = getStoredToken();
    if (storedToken) dispatch(setUserToken(storedToken));
  };
};

export const setUserToken = createAction('USER_SET_TOKEN', (resolve) => (token: string) => {
  return resolve({ token });
});
export const logoutSuccess = createAction('USER_LOGOUT_SUCCESS');

export const fetchCurrentUserIfNeeded = () => {
  return (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState
  ) => {
    if (hasToken(getState())) {
      return dispatch(fetchCurrentUser());
    }
    return Promise.resolve();
  };
};

export const fetchCurrentUserRequest = createAction('USER_FETCH_CURRENT_REQUEST');
export const fetchCurrentUserSuccess = createAction(
  'USER_FETCH_CURRENT_SUCCESS',
  (handler) => (user: User) => {
    return handler({ user });
  }
);
export const fetchCurrentUserError = createAction(
  'USER_FETCH_CURRENT_ERROR',
  (handler) => (error: Error) => {
    return handler({ error });
  }
);

export const fetchCurrentUser = () => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    dispatch(fetchCurrentUserRequest());
    try {
      const token = getToken(getState());
      if (token == null) {
        throw new Error('token is not set');
      }

      const response = await api.fetchCurrentUser(request)({
        token,
      });
      const user = mapToUser(response);
      dispatch(fetchCurrentUserSuccess(user));
    } catch (err) {
      dispatch(fetchCurrentUserError(err));
    }
  };
};

export const getStripeToken = (stripe: ReactStripeElements.StripeProps) => {
  return async () => {
    const payload = await stripe.createToken();
    if (payload.error != null) {
      new Error(`Failed with error code ${payload.error.decline_code}`);
    }
    return payload.token;
  };
};

export const loginRequest = createAction('USER_LOGIN_REQUEST');
export const loginSuccess = createAction('USER_LOGIN_SUCCESS', (handler) => (user: User) => {
  return handler({ user });
});

export const setUserError = createAction('USER_SET_ERROR', (handler) => (error: Error) => {
  return handler({ error });
});

export const updateProfileInformation = (
  { ...params },
  onError: (message: string) => void,
  onSuccess: () => void
) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    try {
      params.token = getToken(getState());
      await api.updateProfileInformation(request)(params);
      await dispatch(fetchCurrentUser());
      onSuccess();
    } catch (err) {
      onError(err.message);
      dispatch(setUserError(new Error(err.message)));
    }
  };
};

export const changeUserPassword = (parameters: {
  currentPassword: string;
  newPassword: string;
}) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    const state = getState();
    const token = getToken(state);
    const user = getCurrentUser(state);

    if (token == null || user == null) {
      throw new Error('You need to be logged in');
    }
    const { currentPassword, newPassword } = parameters;
    await api.changePassword(request)({ currentPassword, newPassword, token });
  };
};

export const updateMembershipStatus = () => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    const parameters = { token: getToken(getState()) };
    await api.updateMembershipStatus(request)(parameters);
    await dispatch(fetchCurrentUser());
  };
};

export const setPasswordResetErrorMessage = createAction(
  'USER_PASSWORD_RESET_ERROR_MESSAGE',
  (handler) => (errorMessage: string) => {
    return handler({ errorMessage });
  }
);

export const setPasswordResetSuccessMessage = createAction(
  'USER_PASSWORD_RESET_SUCCESS_MESSAGE',
  (handler) => (message: string) => {
    return handler({ message });
  }
);

export const setPasswordResetIsLoading = createAction(
  'USER_PASSWORD_RESET_IS_LOADING',
  (handler) => (isLoading: boolean) => {
    return handler({ isLoading });
  }
);

export const setShowLoginButton = createAction(
  'USER_PASSWORD_RESET_SHOW_LOG_IN_BUTTON',
  (handler) => (showLoginButton: boolean) => {
    return handler({ showLoginButton });
  }
);

export const sendUserPasswordResetMail = (email: string) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    const token = getToken(getState());
    dispatch(setPasswordResetIsLoading(true));
    try {
      await api.sendUserPasswordResetMail(request)({ token, email });
      dispatch(
        setPasswordResetSuccessMessage(
          'Du vil om kort tid motta en e-post med en link du må følge for å tilbakestille passordet ditt.'
        )
      );
    } catch (err) {
      dispatch(setPasswordResetErrorMessage((err as Error).message || 'Noe gikk galt.'));
    }
  };
};

export const resetUserPassword = (userId: string, newPassword: string) => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    dispatch(setPasswordResetIsLoading(true));
    try {
      await api.resetUserPassword(request)({ userId, newPassword });
      dispatch(setPasswordResetSuccessMessage('Passordet ditt er nå oppdatert.'));
      dispatch(setShowLoginButton(true));
    } catch (err) {
      dispatch(setPasswordResetErrorMessage((err as Error).message || 'Noe gikk galt.'));
    }
  };
};

export const resetPasswordResetFields = createAction('USER_RESET_PASSWORD_RESET_FIELDS');

export const setIsUserLoading = createAction(
  'USER_SET_LOADING',
  (handler) => (isLoading: boolean) => {
    return handler({ isLoading });
  }
);

export const setActiveTabMenuContent = createAction(
  'USER_SET_ACTIVE_TAB_MENU_CONTENT',
  (handler) => (activeTabMenuContent: TabMenuContent) => {
    return handler({ activeTabMenuContent });
  }
);

export const fetchUserBookings = () => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    dispatch(setIsUserLoading(true));
    try {
      const token = getToken(getState());
      const response = await api.fetchUserBookings(request)({ token });
      dispatch(getUserBookingsSuccess(response as Booking[]));
    } catch (err) {
      console.error(err);
      dispatch(setUserError(new Error(err.message)));
    } finally {
      dispatch(setIsUserLoading(false));
    }
  };
};

export const getUserBookingsSuccess = createAction(
  'USER_GET_BOOKINGS',
  (handler) => (bookings: Booking[]) => {
    return handler({ bookings });
  }
);
