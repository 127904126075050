import * as Yup from 'yup';
import { FormFields } from '../../../auth/components/fields/loginFields';

export interface FormValues {
  name: string;
  email: string;
  phoneNumber: string;
  password: string;
  referralCode: string;
  acceptsTerms: boolean;
}

export const formFields: FormFields[] = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'Fullt navn *',
    error: 'Skriv',
    required: true,
  },
  {
    name: 'email',
    type: 'text',
    placeholder: 'Epost *',
    error: 'Skriv',
    required: true,
  },
  {
    name: 'phoneNumber',
    type: 'tel',
    placeholder: 'Telefonnummer *',
    error: 'Skriv',
    required: true,
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Passord *',
    error: 'Skriv',
    required: true,
  },
  {
    name: 'referralCode',
    type: 'text',
    placeholder: 'Vervekode',
    error: 'Skriv',
    required: false,
  },
  {
    name: 'acceptsTerms',
    type: 'boolean',
    error: 'Påkrevd',
    required: true,
    placeholder:
      'Jeg godtar å få informasjon om mitt medlemskap, og personlige tilbud på mail og sms',
  },
];

export const validation = Yup.object().shape<FormValues>({
  name: Yup.string().min(2, 'For kort!').max(50, 'For lang!').required('Påkrevd å fylle ut navn'),
  email: Yup.string().email('Invalid email').required('Påkrevd å fylle ut epost'),
  phoneNumber: Yup.string().min(8, 'For kort!').max(12, 'For lang!'),
  password: Yup.string().min(2, 'For kort!').max(50, 'For lang!'),
  referralCode: Yup.string(),
  acceptsTerms: Yup.boolean().required(
    'Vilkårene for medlemskap må godkjennes for å opprette medlemsbruker'
  ),
});
