import classNames from 'classnames';
import { H4 } from '../texts';
import styles from './Button.module.css';

type Props = {
  title: string;
} & React.HTMLAttributes<HTMLButtonElement>;

export default ({ title, className, ...props }: Props) => {
  return (
    <button className={classNames(styles.button, className)} onClick={props.onClick}>
      <H4 className={styles.text}>{title}</H4>
    </button>
  );
};
