import React from 'react';
import { appConfig } from '../../../config';
import styles from './Logo.module.css';

type Props = {
  color?: string;
} & React.SVGAttributes<SVGElement>;

const Logo = ({ className, color = '#faedee' }: Props) => {
  return (
    <svg
      width="80px"
      height="36px"
      viewBox="0 0 80 36"
      onClick={() => (window.location.href = appConfig.websiteDomain)}
      className={styles.logo}
    >
      <title>Hjem</title>
      <g id="SVG-Layer" transform="translate(-0.500000, 0.000000)" fill={color}>
        <path
          d="M13.9615 29.7019C9.97528 29.7019 6.73063 26.5051 6.73063 22.5724C6.73063 18.6457 9.97528 15.4428 13.9615 15.4428C17.9538 15.4428 21.1987 18.6457 21.1987 22.5724C21.1987 26.5051 17.9538 29.7019 13.9615 29.7019ZM13.9615 9.73063C6.77425 9.73063 0.925812 15.4919 0.925812 22.5724C0.925812 29.6529 6.77425 35.4141 13.9615 35.4141C21.155 35.4141 27.0035 29.6529 27.0035 22.5724C27.0035 15.4919 21.155 9.73063 13.9615 9.73063Z"
          fill="#faedee"
        />
        <path
          d="M66.8901 29.7018C62.8971 29.7018 59.6514 26.5049 59.6514 22.5721C59.6514 18.6453 62.8971 15.4424 66.8901 15.4424C70.8771 15.4424 74.1226 18.6453 74.1226 22.5721C74.1226 26.5049 70.8771 29.7018 66.8901 29.7018ZM65.5508 9.79749C59.421 10.3989 54.5247 15.2215 53.9142 21.2591C53.5216 25.1245 54.8922 28.9163 57.6704 31.6529C60.1375 34.0827 63.4453 35.4141 66.9025 35.4141C67.3387 35.4141 67.7809 35.3957 68.2234 35.3528C74.353 34.7453 79.2432 29.9287 79.8597 23.8851C80.2524 20.0197 78.8818 16.2339 76.1036 13.4973C73.3251 10.7548 69.4753 9.40487 65.5508 9.79749Z"
          fill="#faedee"
        />
        <path d="M31.5202 35.1623H37.4005V0.414093H31.5202V35.1623Z" fill="#faedee" />
        <path d="M43.3659 35.0784H49.2462V10.1503H43.3659V35.0784Z" fill="#faedee" />
        <path d="M43.3659 6.20546H49.2462V0.414093H43.3659V6.20546Z" fill="#faedee" />
      </g>
    </svg>
  );
};

export default Logo;
