import { createContext } from 'react';
import { useState } from 'react';
import Modal from './Modal';

type ModalContextType = {
  isOpen: boolean;
  closeModal: () => void;
  openModalWithContent: (content: React.ReactNode) => void;
};
export const ModalContext = createContext({} as ModalContextType);

type ModalProviderProps = {
  children: React.ReactNode;
};
export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(<></>);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const openModalWithContent = (content: React.ReactNode) => {
    setModalContent(content);
    openModal();
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        openModalWithContent,
        closeModal,
      }}
    >
      {children}
      {
        <Modal isOpen={isOpen} handleClose={() => setIsOpen(false)}>
          {modalContent}
        </Modal>
      }
    </ModalContext.Provider>
  );
};
