import { useEffect } from 'react';

type Props = {
  targetKey: string;
  targetKeyCode?: number;
  action?: () => void;
};

const useKeyPress = ({ targetKeyCode, targetKey, action }: Props) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === targetKey || event.keyCode === targetKeyCode) {
        action();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [targetKeyCode, action]);
};

export default useKeyPress;
