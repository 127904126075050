import { getPhoneNumberFormatted } from '@askeladden/phonenumber';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import * as yup from 'yup';
import FormInput from '../../../../common/components/form/FormInput';
import { H2 } from '../../../../common/components/texts';
import { getCurrentUser } from '../../../user/forces/selectors';
import { updateGuestInfo } from '../../forces/actions';
import { BookingSectionIDs } from '../../forces/types';
import styles from './GuestSection.module.css';
import { identifyUser, trackBookingStep } from '../../../../common/analytics/posthog';

const GuestSection = () => {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  if (currentUser) return null;

  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
  };
  const guestInfoScheme = yup.object().shape({
    name: yup.string().min(2, '* Skriv inn et gyldig navn').required(),
    email: yup
      .string()
      .required('* Skriv inn en gyldig epost')
      .email('* Skriv inn en gyldig epost'),
    phoneNumber: yup
      .string()
      .test(
        'tel',
        '* Skriv inn et gyldig mobilnummer',
        (value) => !!getPhoneNumberFormatted(value || '', 'NO')
      )
      .required(),
  });
  return (
    <Scroll.Element name={BookingSectionIDs.GuestSection}>
      <section className={styles.container}>
        <H2 className={styles.title}>Detaljer</H2>
        <div className={styles.notice}>
          * Er du Olio medlem må du huske å være innlogget når du booker time.
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={guestInfoScheme}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ values }) => {
            const handleInputChange = (e: React.ChangeEvent<any>) => {
              values[e.target.name] = e.target.value;
              if (
                Object.keys(values).every((key) =>
                  guestInfoScheme.fields[key].isValidSync(values[key])
                )
              ) {
                dispatch(updateGuestInfo(values));
                identifyUser(values);
                trackBookingStep('Gjesteinfo');
              }
            };
            return (
              <Form onInput={handleInputChange}>
                <Field
                  name="name"
                  render={({ field }: FieldProps) => (
                    <FormInput {...field} key="name_input" type="name" placeholder="Navn *" />
                  )}
                />
                <Field
                  name="email"
                  type="email"
                  render={({ field }: FieldProps) => (
                    <FormInput {...field} key="email_input" type="email" placeholder="Epost *" />
                  )}
                />
                <Field
                  name="phoneNumber"
                  type="tel"
                  render={({ field }: FieldProps) => (
                    <FormInput {...field} key="phone_input" type="tel" placeholder="Mobilnr *" />
                  )}
                />
                <ErrorMessage name="name" component="span" className={styles.invalidText} />
                <ErrorMessage name="email" component="span" className={styles.invalidText} />
                <ErrorMessage name="phoneNumber" component="span" className={styles.invalidText} />
              </Form>
            );
          }}
        </Formik>
      </section>
    </Scroll.Element>
  );
};

export default GuestSection;
