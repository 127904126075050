import { RouteComponentProps } from '@reach/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Container } from '../../common/components/grid/Grid';
import Layout from '../../common/components/layout/Layout';
import LoadingIndicator from '../../common/components/loadingIndicator/LoadingIndicator';
import { isAppSetupLoading } from '../app/forces/selectors';
import {
  populateBookingWithExistingBooking,
  resetBookingForm,
  setBookingContext,
} from './forces/actions';
import { BookingContext } from './forces/types';
import CalendarSection from './sections/calendarSection/CalendarSection';
import ClinicSection from './sections/clinicSection/ClinicSection';
import GuestSection from './sections/guestSection/GuestSection';
import ResourceSection from './sections/resourceSection/ResourceSection';
import { getFetchServicesError } from './sections/serviceSection/forces/selectors';
import ServiceSection from './sections/serviceSection/ServiceSection';
import SummarySection from './sections/summarySection/SummarySection';
import posthog from 'posthog-js';
import { getCurrentUser } from '../user/forces/selectors';
import FetchErrorMessage from './components/FetchErrorMessage';
import styles from './Booking.module.css';

interface OwnProps {
  clinicId?: string;
  bookingId?: string;
}

type Props = OwnProps & RouteComponentProps;

const BookingPage = ({ clinicId, bookingId }: Props) => {
  const user = useSelector(getCurrentUser);
  const isAppLoading = useSelector(isAppSetupLoading);
  const serviceFetchError = useSelector(getFetchServicesError);
  const isUpdateMode = clinicId && bookingId;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!posthog.__loaded) {
      return;
    }
    posthog.capture('Booking start', { isUpdate: !!isUpdateMode, isAuthenticated: !!user });
  }, [posthog.__loaded]);

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(populateBookingWithExistingBooking(clinicId, bookingId));
    }
    dispatch(setBookingContext(BookingContext.Create));
    return () => {
      dispatch(resetBookingForm());
    };
  }, [dispatch, clinicId, bookingId]);

  return (
    <Layout>
      {isAppLoading ? (
        <LoadingIndicator />
      ) : (
        <Container className={styles.containerOverride}>
          <Column lgOffset={3} lg={6}>
            {serviceFetchError ? (
              <FetchErrorMessage />
            ) : (
              <>
                <ServiceSection bookingId={bookingId} />
                <ClinicSection />
                <ResourceSection bookingId={bookingId} />
                <CalendarSection />
                <GuestSection />
                <SummarySection />
              </>
            )}
          </Column>
        </Container>
      )}
    </Layout>
  );
};

export default BookingPage;
