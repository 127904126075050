import { Request } from './api.types';
import { checkStatus } from './request.utils';

const request =
  (baseUrl: string) =>
  async <T extends {}>(request: Request): Promise<T> => {
    const { path, method = 'get', body } = request;
    return fetch(`${baseUrl}${path}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...request.headers,
      },
      body,
    })
      .then(checkStatus)
      .then((response) => {
        // Don't try to parse to json if the status is 204 No Content
        if (response.status === 204) {
          return response;
        }
        return response.json();
      })
      .then((response) => response.data);
  };

export default request;
