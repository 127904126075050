import { Booking } from './types';

export const mapResponseToBooking = (response: { [key: string]: any }): Booking => {
  return {
    id: response.id,
    serviceIds: response.serviceIds,
    time: response.time,
    clinicId: response.locationId,
    resourceId: response.resourceId,
    duration: response.duration,
    appliedDiscountPercentage: response.appliedDiscountPercentage,
  };
};

export const mapResponseToBookings = (response: Array<{ [key: string]: any }>): Array<Booking> => {
  return response.map(mapResponseToBooking);
};
