import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../../../common/components/loadingIndicator/LoadingIndicator';
import SectionSubtitle from '../../../common/components/section/SectionSubtitle';
import { BodyText } from '../../../common/components/texts';
import { fetchCurrentUserIfNeeded } from '../forces/actions';
import { getCurrentUser, getIsLoadingUser } from '../forces/selectors';
import styles from './Mypoints.module.css';
import { useOlioPoints } from '../../../common/hooks/useOlioPoints';

const MyOlioPoints = () => {
  const { data } = useOlioPoints();
  const user = useSelector(getCurrentUser);
  const isLoading = useSelector(getIsLoadingUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrentUserIfNeeded());
  }, []);

  if (isLoading) return <LoadingIndicator />;
  if (!user) return null;

  return (
    <div className={styles.textcontainer}>
      <div className={styles.marginContainer}>
        <SectionSubtitle className={styles.text}>
          Du har <span className={styles.pointsAmount}>{data?.usablePoints}</span> olio-poeng!
        </SectionSubtitle>
      </div>
      {data?.reservedPoints > 0 && (
        <div className={styles.remainingPointsContainer}>
          <SectionSubtitle className={styles.text}>
            Dine resterende{' '}
            <span className={styles.remainingPointsAmount}>{data?.reservedPoints}</span> olio-poeng
            kan du benytte etter behandlingene er betalt
          </SectionSubtitle>
        </div>
      )}
      <BodyText className={styles.text}>
        NB! Vi avvikler Olio-poeng og erstatter det med eksklusive medlemstilbud på spesifikke
        behandlinger. Du kan bruke dine tilgjengelige Olio-poeng frem til 31. januar, 2025.
      </BodyText>
    </div>
  );
};

export default MyOlioPoints;
