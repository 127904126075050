import { useDispatch, useSelector } from 'react-redux';
import { RebookingUser } from './useUserByBookingId';
import { useEffect, useState } from 'react';
import { rebookingUserPromptIsSubmitted, setRebookingUser } from '../forces/actions';
import { getSelectedRebookingUser } from '../forces/selectors';

export const useRebookingUser = (
  bookingUser: RebookingUser | null | undefined,
  userWithSamePhone: RebookingUser[]
): {
  showUserSelection: boolean;
  hasSelectedRebookingUser: boolean;
  selectedRebookingUser: RebookingUser;
  handleChangeUser: (userRole: 'member' | 'guest') => void;
} => {
  const dispatch = useDispatch();
  const [hasSelectedRebookingUser, setHasSelectedRebookingUser] = useState<boolean>(false);
  const selectedRebookingUser = useSelector(getSelectedRebookingUser);

  useEffect(() => {
    dispatch(setRebookingUser(bookingUser));
  }, [bookingUser]);

  useEffect(() => {
    if (!canShowUserSelection) {
      dispatch(rebookingUserPromptIsSubmitted(true));
      return;
    }

    if (hasSelectedRebookingUser) {
      dispatch(rebookingUserPromptIsSubmitted(true));
      return;
    }
  }, [hasSelectedRebookingUser]);

  const canShowUserSelection =
    bookingUser &&
    bookingUser?.role === 'guest' &&
    userWithSamePhone.some((user) => user.role === 'member');
  const showUserSelection = canShowUserSelection && !hasSelectedRebookingUser;

  const handleChangeUser = (userRole: 'member' | 'guest') => {
    if (!canShowUserSelection) return;
    setHasSelectedRebookingUser(true);
    const user = userWithSamePhone.find((user) => user.role === userRole);
    dispatch(setRebookingUser(user));
  };

  return {
    showUserSelection,
    selectedRebookingUser,
    hasSelectedRebookingUser,
    handleChangeUser,
  };
};
