import classNames from 'classnames';
import * as React from 'react';
import { assertUnreachable } from '../../utils/ts.utils';
import styles from './FormInput.module.css';

type CallToActionStyleType = 'primary' | 'secondary' | 'uppercase';
export type FormType = 'name' | 'tel' | 'email' | 'text' | 'date' | 'password' | 'boolean';

type CommonProps = {
  styleType?: CallToActionStyleType;
  placeholder?: string;
  required?: boolean;
} & Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type' | 'value' | 'checked'
>;

type BooleanProps = {
  type: 'boolean';
  checked?: boolean;
};

type NonBooleanProps = {
  type?: Exclude<FormType, 'boolean'>;
  value?: string;
};

type Props = CommonProps & (BooleanProps | NonBooleanProps);

const styleTypeToClassName = (styleType: CallToActionStyleType) => {
  switch (styleType) {
    case 'primary':
      return styles.primary;
    case 'secondary':
      return styles.secondary;
    case 'uppercase':
      return styles.uppercase;
    default:
      assertUnreachable(styleType);
  }
};

const getType = (type?: FormType) => {
  switch (type) {
    case 'name':
      return 'name';
    case 'tel':
      return 'tel';
    case 'email':
      return 'email';
    case 'date':
      return 'date';
    case 'password':
      return 'password';
    default:
      return 'text';
  }
};
const getPlaceholder = (placeholder?: string, type?: FormType) => {
  if (placeholder) {
    return placeholder;
  }
  return getType(type);
};

const FormInputWith = ({
  className,
  required = true,
  styleType = 'primary',
  type = 'text',
  ...props
}: Props) => {
  if (type === 'boolean') {
    return (
      <div className={styles.checkboxContainer}>
        <input
          id={'checkbox'}
          type="checkbox"
          placeholder=""
          {...props}
          className={styles.checkbox}
          required={required}
        />
        <label htmlFor={'checkbox'}>
          <p className={styles.checkboxLabel}>{props.placeholder}</p>
        </label>
      </div>
    );
  }

  return (
    <div className={classNames(styles.container, className)}>
      <input
        {...props}
        type={getType(type)}
        className={classNames(styles.input, styleTypeToClassName(styleType))}
        placeholder={getPlaceholder(props.placeholder, type)}
        required={required}
      />
    </div>
  );
};

export default FormInputWith;
