import * as React from 'react';
import CallToAction, { CallToActionProps } from './CallToAction';

type Props = {} & CallToActionProps & React.ButtonHTMLAttributes<HTMLElement>;

const CallToActionButton: React.FunctionComponent<Props> = ({ ...props }) => {
  return <CallToAction element="button" {...props} />;
};

export default CallToActionButton;
