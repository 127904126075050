import { stringifyObjectWithBrackets } from '../../../../../common/utils/url.utils';
import { APIRequest } from '../../../../../root.types';
import { AvailableDate } from './types';

const fetchAvailability =
  (request: APIRequest) =>
  async ({
    serviceIds,
    clinicId,
    from,
    to,
    resourceId,
  }: {
    serviceIds: string[];
    clinicId: string;
    from?: string;
    to?: string;
    bookingId?: string;
    resourceId?: string;
  }) => {
    const serviceQueryParameters = stringifyObjectWithBrackets({
      serviceIds,
      from,
      to,
      resourceId,
    });
    return request<AvailableDate[]>({
      path: `/clinics/${clinicId}/availability?${serviceQueryParameters}`,
    });
  };

export default {
  fetchAvailability,
};
