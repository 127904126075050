import ErrorHandler, { providers } from '@askeladden/error-tracker-node';
import * as Sentry from '@sentry/react';
import { appConfig } from '../../config';

const ErrorTracker = (() =>
  new ErrorHandler(
    // @ts-ignore
    new providers.Sentry(Sentry, {
      dsn: appConfig.sentry.dsn,
      environment: 'production',
      isActive: true,
    })
  ))();

export default ErrorTracker;
