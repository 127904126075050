import classNames from 'classnames';
import * as React from 'react';
import * as ReactGrid from 'react-flexbox-grid';
import styles from './Grid.module.css';

type ContainerProps = ReactGrid.GridProps;

export const Container: React.FunctionComponent<ContainerProps> = ({ className, ...props }) => {
  return (
    <ReactGrid.Grid className={classNames(styles.container, className)} {...props}>
      {props.children}
    </ReactGrid.Grid>
  );
};

type RowProps = ReactGrid.RowProps;
export const Row: React.FunctionComponent<RowProps> = (props) => {
  return <ReactGrid.Row {...props}>{props.children}</ReactGrid.Row>;
};

type ColumnProps = ReactGrid.ColProps;
export const Column: React.FunctionComponent<ColumnProps> = ({ children, xs = true, ...props }) => {
  return (
    <ReactGrid.Col xs={xs} {...props}>
      {children}
    </ReactGrid.Col>
  );
};
