import { replaceParameters } from '../../common/utils/routes.utils';
import { appConfig } from '../../config';

export enum RoutePaths {
  Main = '/',
  Rebooking = '/rebooking/:bookingId',
  BookingSuccess = '/takk',
  RebookingSuccess = '/rebooking-bekreftelse',
  LogIn = '/login',
  Register = '/register',
  UserPage = '/min-side',
  ChangePassword = '/oppdater-passordet',
  UpdateBooking = '/booking/update/:clinicId/:bookingId',
  Logout = '/logout',
  GuestAuthentication = '/guests/:userId',
  ForgotPassword = '/glemt-passord',
  Giftcard = '/gavekort',
  AboutUs = '/om-oss',
  Treatments = '/behandlinger',
  Locations = '/lokasjoner',
  Articles = '/artikler-og-nyheter',
  ContactUs = '/kontakt-oss',
  Investment = '/bli-medeier',
  Laser = '/behandlingskategorier/laserbehandling',
}

export default {
  Main: () => RoutePaths.Main,
  Rebooking: () => RoutePaths.Rebooking,
  BookingSuccess: () => RoutePaths.BookingSuccess,
  RebookingSuccess: () => RoutePaths.RebookingSuccess,
  LogIn: () => RoutePaths.LogIn,
  Register: () => RoutePaths.Register,
  Logout: () => RoutePaths.Logout,
  UserPage: () => RoutePaths.UserPage,
  UpdateBooking: (parameters: { clinicId: string; bookingId: string }) => {
    return replaceParameters(RoutePaths.UpdateBooking, parameters);
  },
  ChangePassword: () => RoutePaths.ChangePassword,
  ForgotPassword: () => RoutePaths.ForgotPassword,

  // website pages
  Giftcard: () => appConfig.websiteDomain + RoutePaths.Giftcard,
  Laser: () => appConfig.websiteDomain + RoutePaths.Laser,
  Investment: () => appConfig.websiteDomain + RoutePaths.Investment,
  AboutUs: () => appConfig.websiteDomain + RoutePaths.AboutUs,
  Treatments: () => appConfig.websiteDomain + RoutePaths.Treatments,
  Locations: () => appConfig.websiteDomain + RoutePaths.Locations,
  Articles: () => appConfig.websiteDomain + RoutePaths.Articles,
  ContactUs: () => appConfig.websiteDomain + RoutePaths.ContactUs,

  // external pages
  Dealflow: () => 'https://dealflow.no/case/olio-as/oversikt',
  Typeform: () => appConfig.typeform.url,
};
