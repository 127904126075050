import CallToActionButton from '../../../../../common/components/callToAction/CallToActionButton';
import { Clinic } from '../forces/types';
import styles from './ClinicButtons.module.css';

interface Props {
  clinics: Clinic[] | null;
  handleSelectClinic: (clinic: Clinic) => void;
  selectedClinicId: string | null;
}

const ClinicButtons = ({ clinics, handleSelectClinic, selectedClinicId }: Props) => {
  if (!clinics) return null;

  return (
    <>
      {clinics.map((clinic) => (
        <div className={styles.clinicButton} key={clinic.id}>
          <CallToActionButton
            style={{ width: '100%' }}
            styleType={clinic.id === selectedClinicId ? 'primary' : 'outlinedQuaternary'}
            size="normal"
            onClick={() => handleSelectClinic(clinic)}
          >
            {clinic.name}
          </CallToActionButton>
        </div>
      ))}
    </>
  );
};

export default ClinicButtons;
