import { stringifyObjectWithBrackets } from '../../../../../common/utils/url.utils';
import { APIRequest } from '../../../../../root.types';

const fetchAllClinics = (request: APIRequest) => async () => {
  return request({
    path: `/clinics`,
  });
};

const fetchClinicsThatServeSelectedServices =
  (request: APIRequest) => async (selectedServiceIds: string[]) => {
    const serviceQueryParameters = stringifyObjectWithBrackets({
      serviceIds: selectedServiceIds,
    });
    return request<string[]>({ path: `/services/clinics/?${serviceQueryParameters}` });
  };

export default {
  fetchAllClinics,
  fetchClinicsThatServeSelectedServices,
};
