import { createReducer } from 'deox';
import { Service } from '../../../../../root.types';
import { fetchServicesError, fetchServicesRequest, fetchServicesSuccess } from './actions';

export interface State {
  services: Service[] | null;
  isLoading: boolean;
  error: Error | null;
}

const defaultState: State = {
  services: null,
  isLoading: false,
  error: null,
};

const serviceReducers = createReducer(defaultState, (handle) => [
  handle(fetchServicesRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  handle(fetchServicesSuccess, (state, { payload }) => ({
    ...state,
    services: payload.services,
    isLoading: false,
  })),
  handle(fetchServicesError, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload.error,
  })),
]);

export default serviceReducers;
