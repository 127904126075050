import Scroll from 'react-scroll';
import { useSelector } from 'react-redux';
import CallToActionButton from '../../../../../../common/components/callToAction/CallToActionButton';
import FormAlert from '../../../../../../common/components/form/FormAlert';
import Routes from '../../../../../app/Routes';
import { getAuthError } from '../../../../../auth/forces/selectors';
import { updateBookingError } from '../../../../forces/actions';
import { getBookingError, getIsLoadingBooking } from '../../../../forces/selectors';
import styles from './SummarySectionNew.module.css';
import { navigate } from '@reach/router';
import { useDispatch } from '../../../../../../common/store/hooks';
import { SmallText } from 'common/components/texts';

type BookSectionProps = {
  handleEditBooking: () => void;
  isDisabled: boolean;
};
const EditBookingSection = ({ handleEditBooking, isDisabled }: BookSectionProps) => {
  const bookingError = useSelector(getBookingError);
  const errorMessage = bookingError
    ? 'Det oppsto en feil. Vennligst prøv å velg et annet tidspunkt eller ta kontakt med oss for å få hjelp til å booke timen!'
    : null;
  const isLoading = useSelector(getIsLoadingBooking);
  const authError = useSelector(getAuthError);
  const dispatch = useDispatch();

  if (bookingError || authError) Scroll.animateScroll.scrollToBottom();
  if (errorMessage)
    return (
      <div className={styles.deleteContainer}>
        <FormAlert error={errorMessage} onClose={() => dispatch(updateBookingError(null))} />
        <CallToActionButton
          size="normal"
          styleType="tertiary"
          onClick={() => navigate(Routes.Typeform())}
        >
          Kontakt oss
        </CallToActionButton>
      </div>
    );

  return (
    <div className={styles.bookSectionContainer}>
      <SmallText className={styles.infoContainer}>
        Ved å booke godtar du Olio sine{' '}
        <a
          className={styles.underline}
          href="https://www.olio.no/personvern-og-vilkar"
          target="_blank"
        >
          vilkår for avbestilling og bruk
        </a>
      </SmallText>
      <button className={styles.bookButton} onClick={handleEditBooking} disabled={isDisabled}>
        {isLoading ? 'Oppdaterer timen din...' : 'Oppdater'}
      </button>
    </div>
  );
};

export default EditBookingSection;
