import classNames from 'classnames';
import React from 'react';
import Navbar from '../../navBar/Navbar';
import styles from './Layout.module.css';

type Props = {
  title?: string;
} & React.HTMLAttributes<HTMLElement>;
const Layout = ({ children, className }: Props) => {
  return (
    <div className={classNames(className)}>
      <Navbar />
      <div className={styles.childWrapper}>{children}</div>
    </div>
  );
};

export default Layout;
