import { SegmentWeb } from '@askeladden/segment-node';
import { User } from '@sentry/react';
import { appConfig } from '../../config';
import { GuestInfo } from '../../domain/user/forces/types';
import { splitFirstAndLastName } from '../utils/formatting.utils';

export const segment = new SegmentWeb({ writeKey: appConfig.segment.writeKey });

export const getCookies = () => {
  return document.cookie;
};
export const identifyUser = async (user: User | GuestInfo) => {
  if (appConfig.environment === 'development') {
    console.log('Skipping identify while in development');
    return;
  }

  try {
    const { firstName, lastName } = splitFirstAndLastName(user.name);
    await segment.identify(user.email, {
      email: user.email,
      firstName,
      lastName,
      phone: user.phoneNumber,
    });
  } catch (err) {
    console.log('Failed segment-call');
  }
};
