import { RootState } from '../../../../../root.reducer';
import { Clinic } from './types';

export const getIsLoadingClinic = (state: RootState): boolean => state.clinic.isLoading;
export const getClinics = (state: RootState): Clinic[] | null => state.clinic.clinics;
export const getBookableClinics = (state: RootState) => state.clinic.bookableClinics.clinics;
export const getIsLoadingBookableClinics = (state: RootState) =>
  state.clinic.bookableClinics.isLoading;
export const getOtherBookableClinics = (state: RootState) =>
  state.clinic.otherBookableClinics.clinics;
export const getShouldFetchOtherBookableClinics = (state: RootState) =>
  state.clinic.otherBookableClinics.shouldFetch;
export const getIsLoadingOtherBookableClinics = (state: RootState) =>
  state.clinic.otherBookableClinics.isLoading;
