import Scroll from 'react-scroll';
import { Service } from '../../../root.types';
import { BookingSectionIDs } from './types';

export const getBookingSectionScrollOffset = (bookingSectionID: BookingSectionIDs) => {
  switch (bookingSectionID) {
    case BookingSectionIDs.CalendarSection:
      return 50;
    case BookingSectionIDs.Summary:
      return 250;
    default:
      return 0;
  }
};

export const scrollToBookingSection = (id: BookingSectionIDs) => {
  const offset = getBookingSectionScrollOffset(id);
  Scroll.scroller.scrollTo(id, {
    smooth: true,
    offset,
  });
};

const findParentOfChild = (services: Service[], childService: Service) => {
  return services.find((service) =>
    service.additionalServices.find((s) => s.id === childService.id)
  );
};

export const getFilteredServices = (
  selectedServices: Service[]
): {
  filteredServices: Service[];
  excludedServices: Service[];
} => {
  if (!selectedServices)
    return {
      filteredServices: [],
      excludedServices: [],
    };
  /*
    We do not send all selected services to server. 
    
    If a service has child service(s) and child(ren) is/are of package deal type, we omit parent service and only send
    child(ren) with package deal if thoese child(ren) is/are selected, otherwise we send only the parent service.

    if a service has child service(s) and child(ren) is/are NOT of package deal, we send both parent and child service(s) to server.
  */

  const excludedParents = []; // parents to exclude if it has child with package deal
  const parentServices = selectedServices.filter((service) => !service.isChild);
  // find child with package deal
  selectedServices.filter((service) => {
    const isChildAndPackageDeal = service.isChild && service.isPackageDeal;
    if (isChildAndPackageDeal) {
      const parentOfThisChild = findParentOfChild(parentServices, service);
      if (!parentOfThisChild) return;
      excludedParents.push(parentOfThisChild);
    }
  });

  // exclude parents with child of package deal in final selected services
  if (excludedParents.length > 0) {
    const filteredServices = selectedServices.filter(
      (service) => !excludedParents.find((parent) => parent?.id === service?.id)
    );
    return {
      filteredServices,
      excludedServices: excludedParents,
    };
  }

  return { filteredServices: selectedServices, excludedServices: [] };
};
