import { createAction } from 'deox';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../../../root.reducer';
import { Service, ThunkArguments } from '../../../../../root.types';
import * as api from './api';

export const fetchServicesRequest = createAction('SERVICES_FETCH_SERVICES_REQUEST');
export const fetchServicesSuccess = createAction(
  'SERVICES_FETCH_SERVICES_SUCCESS',
  (resolve) => (services: Service[] | null) => {
    return resolve({ services });
  }
);
export const fetchServicesError = createAction(
  'FETCH_SERVICES_ERROR',
  (resolve) => (error: Error) => {
    return resolve({ error });
  }
);

export const fetchServices = () => {
  return async (
    dispatch: ThunkDispatch<RootState, ThunkArguments, AnyAction>,
    getState: () => RootState,
    { request }: ThunkArguments
  ) => {
    dispatch(fetchServicesRequest());
    try {
      const res = await api.fetchServices(request)();
      dispatch(fetchServicesSuccess(res));
    } catch (err) {
      dispatch(fetchServicesError(err));
    }
  };
};
