import { RootState } from '../../../../../root.reducer';

export const getResources =
  (state: RootState) =>
  (withAcoBookingResources: boolean = false) => {
    if (!withAcoBookingResources) {
      return state.resource.resources;
    }
    const res = [
      ...(state.resource.resources ?? []),
      ...(state.resource.acoBookingResources ?? []),
    ];
    return res;
  };

export const isLoading = (state: RootState) => state.resource.isLoading;
export const isError = (state: RootState) => state.resource.error;
export const getResourceById =
  (state: RootState) =>
  (resourceId: string, withAcoBookingResources: boolean = false) => {
    if (!state.resource.resources) return;
    const resourcePool = withAcoBookingResources
      ? [...state.resource.resources, ...state.resource.acoBookingResources]
      : state.resource.resources;
    return resourcePool.find((resource) => resource.id === resourceId);
  };
