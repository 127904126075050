import { useSelector } from 'react-redux';
import { Service } from '../../../../../root.types';
import { getSelectedServices, getServiceChildServices } from '../../../forces/selectors';
import styles from './ServiceItem.module.css';
import CheckboxLine from './CheckboxLine';
import { useMemo } from 'react';
import ServicePrice from './ServicePrice';

interface Props {
  childServices: Service[];
  parentService: Service;
  handleSelection: (service: Service) => void;
  handleDeselection: (service: Service) => void;
  resetSections: () => void;
}
const RequiredChildServiceItems = ({
  childServices,
  parentService,
  handleSelection,
  handleDeselection,
  resetSections,
}: Props) => {
  const selectedServices = useSelector(getSelectedServices);
  const selectedChildServices = useSelector(getServiceChildServices)(parentService);
  const sortedChildServices = useMemo(
    () => childServices.sort((a, b) => (a.sort > b.sort ? 1 : -1)),
    [childServices]
  );

  const handleRadioSelection = (service: Service) => {
    if (selectedChildServices.length > 0) {
      handleDeselection(selectedChildServices[0]);
    }
    handleSelection(service);
  };

  return (
    <>
      {sortedChildServices.map((childService) => {
        const isSelected =
          selectedServices && selectedServices.find((service) => service.id === childService.id);
        return (
          <div
            className={styles.childServiceContainer}
            key={childService.id}
            onClick={(e: any) => {
              if (isSelected) {
                // Shouldn't be able to deselect a radio button
                e.stopPropagation();
                return;
              }
              handleRadioSelection(childService);
              resetSections();
              e.stopPropagation();
            }}
          >
            <CheckboxLine
              isRadioButton
              onClick={() => {}}
              isSelected={!!isSelected}
              content={childService.displayTitle}
              className={styles.childService}
              tooltip={childService.description}
            />
            <ServicePrice service={childService} />
          </div>
        );
      })}
    </>
  );
};

export default RequiredChildServiceItems;
