import * as React from 'react';
import classNames from 'classnames';
import styles from './Texts.module.css';

type TagNames = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

type Props = {} & React.HTMLAttributes<HTMLElement>;
const createTextComponent = (className: string, tagName: TagNames | undefined = 'h1') => {
  return class TextComponent extends React.Component<Props> {
    render() {
      const props = {
        ...this.props,
        className: classNames(this.props.className, className, styles.defaultTextStyle),
      };
      return React.createElement(tagName, props);
    }
  };
};

export default createTextComponent;
