import { getCookies, identifyUser } from '../../../common/analytics/segment';
import { APIRequest } from '../../../root.types';
import { UserResponse } from '../../user/forces/types';

export const loginMember =
  (request: APIRequest) => async (parameters: { phoneNumber: string; password: string }) => {
    const { phoneNumber, password } = parameters;
    return request<UserResponse>({
      method: 'post',
      path: '/auth/signin/user',
      body: JSON.stringify({ phoneNumber, password }),
    });
  };

export const loginGuest =
  (request: APIRequest) =>
  async ({ userId }: { userId: string }) => {
    return request<UserResponse>({
      method: 'post',
      path: '/auth/signin/guest',
      body: JSON.stringify({
        id: userId,
      }),
    });
  };

export const createGuest =
  (request: APIRequest) =>
  async ({ phoneNumber, name, email }: { phoneNumber: string; name: string; email: string }) => {
    await identifyUser({ phoneNumber, name, email });
    const cookies = getCookies();
    return request({
      method: 'post',
      path: '/auth/signin/guest',
      body: JSON.stringify({
        phoneNumber,
        name,
        email,
        cookies,
      }),
    });
  };

export default {
  loginGuest,
  loginMember,
  createGuest,
};
