import { BodyText, H1, H3 } from '../../common/components/texts';
import styles from './Booking.module.css';
import classNames from 'classnames';
import { RebookingUser } from './hooks/useUserByBookingId';
import { useRebookingUser } from './hooks/useRebookingUser';
import LoadingIndicator from '../../common/components/loadingIndicator/LoadingIndicator';

type Props = {
  therapistName: string;
  bookingUser: RebookingUser;
  userWithSamePhone: RebookingUser[];
};

const RebookingOverview = ({ therapistName, bookingUser, userWithSamePhone }: Props) => {
  const { showUserSelection, selectedRebookingUser, hasSelectedRebookingUser, handleChangeUser } =
    useRebookingUser(bookingUser, userWithSamePhone);

  if (!selectedRebookingUser) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <H1 className={styles.header}>Rebooking</H1>
      <div className={styles.rebookingOverviewSection}>
        <div className={styles.summaryColumn}>
          <BodyText>Gjennomføres av terapeut:</BodyText>
          {therapistName ? (
            <H3>{therapistName}</H3>
          ) : (
            <H3 className={styles.loadingTextIndicator}>...</H3>
          )}
        </div>
        <div className={styles.summaryColumn}>
          <BodyText>Bestilt på vegne av kunde:</BodyText>
          <div className={styles.flexBetween}>
            <div className={styles.flexGap}>
              <H3>{selectedRebookingUser.name}</H3>
              <H3 className={styles.thin}>({selectedRebookingUser.role})</H3>
            </div>
            {hasSelectedRebookingUser && (
              <label htmlFor="change-rebooking-user" className={styles.iconUnderlineButton}>
                <EditIcon />
                <button
                  className={styles.strippedButton}
                  id="change-rebooking-user"
                  onClick={() =>
                    selectedRebookingUser.role === 'guest'
                      ? handleChangeUser('member')
                      : handleChangeUser('guest')
                  }
                >
                  Endre til{' '}
                  {selectedRebookingUser.role === 'guest' ? 'medlemsbruker' : 'gjestebruker'}
                </button>
              </label>
            )}
          </div>
        </div>

        {showUserSelection && (
          <div className={styles.userRoleSection}>
            <div className={styles.iconTextContainer}>
              <InfoIcon />
              <BodyText>
                Vi fant en medlemsbruker. Vil du bruke den slik at kunden tjener Olio-poeng?
              </BodyText>
            </div>

            <div className={styles.buttonGroup}>
              <button
                className={classNames(styles.userRoleButton, styles.primaryButton)}
                onClick={() => handleChangeUser('member')}
              >
                Bruk medlemsbruker
              </button>
              <button
                className={classNames(styles.userRoleButton, styles.secondaryButton)}
                onClick={() => handleChangeUser('guest')}
              >
                Fortsett med gjestebruker
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RebookingOverview;

const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    height={24}
    width={24}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
    />
  </svg>
);

const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    height={24}
    width={24}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
    />
  </svg>
);
