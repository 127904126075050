import CallToActionLink from '../../../common/components/callToAction/CallToActionLink';
import { Column, Container, Row } from '../../../common/components/grid/Grid';
import { BodyBolderText, H1 } from '../../../common/components/texts';
import Routes from '../../app/Routes';
import styles from './RegistrationSummary.module.css';

type Props = {} & React.HTMLAttributes<HTMLElement>;

const RegistrationSummary = (props: Props) => {
  return (
    <Container>
      <Row>
        <Column xs={12} sm={8} smOffset={2} className={styles.message}>
          <H1 className={styles.title}>Hurra! Du har nå blitt medlem av olio 💕</H1>
          <div className={styles.callToActionsContainer}>
            <CallToActionLink to={Routes.Main()} styleType="tertiary" size="large">
              Book time
            </CallToActionLink>
            <BodyBolderText className={styles.orText}>eller</BodyBolderText>
            <CallToActionLink theme="dark" styleType="outlined" to={Routes.UserPage()} size="large">
              Gå til min side
            </CallToActionLink>
          </div>
        </Column>
      </Row>
    </Container>
  );
};

export default RegistrationSummary;
