import { createActionCreator } from 'deox';
import { Dispatch } from 'redux';
import { RootState } from '../../../../../root.reducer';
import { Service, ThunkArguments } from '../../../../../root.types';
import { Resource } from '../types';
import api from './api';

export type CombinedResources = {
  resources: Resource[];
  acoBookingResources: Resource[];
};
export const resetResources = createActionCreator('CLINIC_RESET_RESOURCES');
export const fetchResourcesRequest = createActionCreator('CLINIC_FETCH_RESOURCES_REQUEST');
export const fetchResourcesSuccess = createActionCreator(
  'CLINIC_FETCH_RESOURCES_SUCCESS',
  (resolve) => (resources: CombinedResources | null) => {
    return resolve({ resources });
  }
);
export const fetchResourcesError = createActionCreator(
  'CLINIC_FETCH_RESOURCES_ERROR',
  (resolve) => (error: Error) => {
    return resolve({ error });
  }
);

export const fetchResources = (clinicId: string, services?: Service[]) => {
  return async (dispatch: Dispatch, getState: () => RootState, { request }: ThunkArguments) => {
    dispatch(fetchResourcesRequest());
    try {
      const serviceIds = services
        ? services.map((service) => service.waitwhileServiceId) // waitwhileServiceId is also the ACO Booking id for ACO Booking services
        : undefined;
      const res = await api.fetchResources(request)(clinicId, serviceIds);
      dispatch(fetchResourcesSuccess(res));
    } catch (err) {
      dispatch(fetchResourcesError(err));
    }
  };
};
