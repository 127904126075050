import useSWR from 'swr';
import { useAuthenticatedFetcher } from './useAuthenticatedFetcher';
import { appConfig } from '../../config';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../domain/user/forces/selectors';

type OlioPointsResponse = {
  usablePoints: number;
  reservedPoints: number;
};
export const useOlioPoints = () => {
  const user = useSelector(getCurrentUser);
  const fetcher = useAuthenticatedFetcher();
  const { data, ...params } = useSWR<{ data: OlioPointsResponse }>(
    () => (user ? appConfig.apiBaseUrl + '/users/current/points' : null),
    fetcher
  );

  if (!data) return { data: undefined, ...params };

  const mappedData = {
    usablePoints: data.data.usablePoints || 0,
    reservedPoints: data.data.reservedPoints || 0,
  };

  return { data: mappedData, ...params };
};
