import { navigate, RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Column, Container } from '../../../common/components/grid/Grid';
import Layout from '../../../common/components/layout/Layout';
import { BodyText, H2, H4 } from '../../../common/components/texts';
import { formatIsoToDateAndYear, formatIsoToTime } from '../../../common/utils/date.utils';
import Routes from '../../app/Routes';
import { resetBookingForm } from '../forces/actions';
import styles from './BookingSuccess.module.css';

type Props = RouteComponentProps & React.HTMLAttributes<HTMLElement>;

const RebookingSuccess = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBookingForm());
  }, []);

  if (!props.location.state) {
    window.location.href = Routes.Main();
    return null;
  }

  const { selectedServices, selectedClinic, selectedTime } = props.location.state;
  if (!selectedServices && !selectedClinic && !selectedTime) {
    navigate(Routes.Main());
    return null;
  }
  const date = formatIsoToDateAndYear(selectedTime);
  const time = formatIsoToTime(selectedTime);
  const location = selectedClinic
    ? `${selectedClinic.name} (${selectedClinic.address.streetAddress})`
    : '';
  const welcomeText = `Vi gleder oss til en ny behandling, ${date} klokken ${time} på ${location}`;

  return (
    <Layout className={styles.container}>
      <Container>
        <Column xs={12} md={6} mdOffset={3} lg={6} lgOffset={3}>
          <H2 className={styles.title}>{'Rebooking vellykket!'}</H2>
          <div></div>
          <BodyText>{welcomeText}</BodyText>
          <H4>Kunden vil motta en SMS med bekreftelse på rebookingen</H4>
          <H4 className={styles.noMarginBottom}>
            Minn kunden på at Olio har 24 timers endrings-/avbestillingsfrist
          </H4>
        </Column>
      </Container>
    </Layout>
  );
};

export default RebookingSuccess;
