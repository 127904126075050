import { useDispatch, useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import CallToActionButton from '../../../../../common/components/callToAction/CallToActionButton';
import LoadingIndicator from '../../../../../common/components/loadingIndicator/LoadingIndicator';
import { BodyText } from '../../../../../common/components/texts';
import { createRebooking, validateAndScrollBookingSections } from '../../../forces/actions';
import {
  getBookingError,
  getInvalidSections,
  getIsLoadingBooking,
  getIsRebookingValid,
  getSelectedRebookingUser,
} from '../../../forces/selectors';
import { BookingSectionIDs } from '../../../forces/types';
import styles from './CreateUpdateDeleteBookingButtons.module.css';

const CreateRebookingButton = () => {
  const bookingError = useSelector(getBookingError);
  const isLoading = useSelector(getIsLoadingBooking);
  const isRebookingValid = useSelector(getIsRebookingValid);
  const styleType = isRebookingValid ? 'tertiary' : 'secondary';
  const disableBookingButton = !isRebookingValid || bookingError ? true : false;
  const invalidSections = useSelector(getInvalidSections)(['currentUser']);
  const selectedRebookingUser = useSelector(getSelectedRebookingUser);
  const dispatch = useDispatch();

  if (isLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingIndicator className={styles.loadingContainer} loadingText={'Booker timen'} />
      </div>
    );
  }

  const handleCreateBooking = () => {
    dispatch(
      createRebooking({ rebookingUserid: selectedRebookingUser.id, isMemberBookedAsGuest: false })
    );
    Scroll.animateScroll.scrollToBottom();
  };

  return (
    <Scroll.Element name={BookingSectionIDs.CreateUpdateDeleteBooking}>
      <div className={styles.updateAndDeleteButtonsContainer}>
        <CallToActionButton
          size="large"
          styleType={styleType}
          disabled={disableBookingButton}
          onClick={handleCreateBooking}
          className={styles.createOrUpdateBookingButton}
        >
          Rebook
        </CallToActionButton>
        <div
          className={styles.invalidSecitons}
          onClick={() => dispatch(validateAndScrollBookingSections())}
        >
          {invalidSections.map((invalidSection) => (
            <BodyText key={invalidSection} className={styles.invalidText}>
              * {invalidSection}
            </BodyText>
          ))}
        </div>
      </div>
    </Scroll.Element>
  );
};

export default CreateRebookingButton;
